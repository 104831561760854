.text-shadow {
	text-shadow: $text-shadow;
}

.text-green-light {
	color: $green-200;
}

.lead {
	margin-bottom: $lead-margin-bottom;
	@include media-breakpoint-down(sm) {
		font-size: 1.2rem;
	}
}

.heading-special {
	font-family: $font-family-serif;
	@include font-size(2.5rem);
	@extend .mb-4; 
}


//Utilities
.isolate {
	isolation: isolate;
}

.img-wrapper-stretch {
  width: 200px;
  height: 150px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}

.img-wrapper-stretch > img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
}

// COMPONENTS

.container-section {
	@extend .container-xl;
	@extend .px-3;
	@extend .px-md-4;
}

// .container-xl {
// 	@extend .px-2;
// 	@extend .px-sm-3;
// 	@extend .px-md-4;
// }

// @mixin make-container($gutter: $container-padding-x) {
//   width: 100%;
//   padding-right: var(--#{$variable-prefix}gutter-x, #{$gutter});
//   padding-left: var(--#{$variable-prefix}gutter-x, #{$gutter});
//   margin-right: auto;
//   margin-left: auto;
// }

//Menu

.navbar-brand {
	img {
		width: 120px;
		height: 92px;
		@include media-breakpoint-up(sm) {
			width: 180px;
			height: 138px;
		}
	}
}

.navbar-nav {
	@extend .justify-content-end;
	@extend .py-lg-4;
	@extend .flex-grow-1;
	.nav-link {
		font-size: 1.1rem;
		font-weight: 500;
	}
	.dropdown-toggle {
		color: $navbar-dark-color;
		@extend .nav-link;
		&:after {
			margin-left: .5em;
		}
		&:hover,
		&:focus {
			color: $navbar-dark-hover-color;
			box-shadow: none;
		}
	}
	.dropdown-menu {
		@extend .dropdown-menu-dark;
	}
	.dropdown-wrapper {
		@extend .list-unstyled;
	}
}

.offcanvas-header {
 	padding: 0 $offcanvas-padding-x;
}

@include media-breakpoint-down(lg) {
	.nav-item, .dropdown {
		padding-bottom: .25rem;
		&:not(:first-child) {
			padding-top: .25rem;
		}
		.btn {
			text-align: left;
		}
	}
}

.animate-dropdown {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-name: slideIn;
	animation-timing-function: ease-out;
}


@keyframes slideIn {
	0% {
		transform: translateY(-1rem);
		opacity: 0;
	}

	100% {
		transform: translateY(0rem);
		opacity: 1;
	}

	0% {
		transform: translateY(-1rem);
		opacity: 0;
	}
}

.navbar-toggler {
	// margin-top: $nav-list-padding;
	align-self: center;
	border-radius: 50%;
	padding: .75rem;
	border-color: transparent;
	background-color: rgba($white, .35);
	@include transition($navbar-toggler-transition);
	&:hover {
		background-color: rgba($white, .5);
	}
	&:focus {
		box-shadow: none;
	}
}

.navbar-toggler-icon {
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

.dropdown-menu {
	@extend .animate-dropdown;
}

//Nav list (services)

.nav-list {
	background-color: $nav-list-bg;
	padding: $nav-list-padding;

	.nav-item {
		@extend .border-bottom;
		@extend .border-secondary;
	}

	.nav-link {
		background: none;
		border: 0;
		@include border-radius($nav-list-border-radius);
		// font-decoration: $nav-list-link-decoration;
		color: $nav-list-link-color;
		font-size: 1.2rem;
		@extend .px-1;
		@extend .py-lg-2;
		text-align:left;
		@include media-breakpoint-down(sm) {
			font-size: 1rem;
		}
		&:hover, &:focus {
			color: $nav-list-link-hover-color;
		}
	}

	.nav-link.active,
	.show > .nav-link {
		color: $nav-list-link-active-color;
		@include gradient-bg($nav-list-link-active-bg);
		font-weight: bold;
	}
}

//Close

.btn-close, .offcanvas-header .btn-close, .modal-header .btn-close {
	@extend .btn-close-white; 
	@extend .ms-auto;
	border-radius: 50%;
	border-color: transparent;
	background-color: rgba($white, .5);
	@include transition($navbar-toggler-transition);
	&:hover {
		background-color: rgba($white, .5);
	}
}

.offcanvas-header .btn-close, .modal-header .btn-close {
	margin-top: 1em;
	margin-bottom: 0;
	margin-right: 0;
	padding: $btn-close-padding-y $btn-close-padding-x;
}

//Buttons

.btn-row {
	.btn {
		@extend .mb-3;
	}
}

.btn-row-left a {
	&:not(:last-child) {
		@extend .me-3;
	}
}

.btn-gray {
	@include button-variant ($charcoal-200, $charcoal-200);
	color: $white;
}

.btn-white {
	@include button-variant ($white, $white);
}

//Cards

.card {

	.btn {
		@extend .mb-3;
	}
	h3 {
		@extend h4;
	}
}

h4.card-body, h3.card-body {
	margin-bottom: 0;
}

.card-img {
	@extend .img-fluid;
}


.card-dark {
	a:not(.btn) {
		@extend .link-light;
	}
}

.card-group {
	@extend .row;
	@extend .justify-content-center;
}

.card-group-2 {
		@extend .col-lg-6;
		@extend .col-md-8;
		@extend .mb-md-5;
		@extend .mb-4;
}

.card-group-3 {
	@extend .col-lg-4;
	@extend .col-sm-6;
	@extend .mb-sm-5;
	@extend .mb-4;
}

.card-white {
	background-color: white;
	color: $body-color;
}

// Shape Images - TODO

.shaped {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
}

%drop {
	content: "";
	position: absolute;
	background-image: url('../img/bg-water.svg');
	background-repeat: no-repeat;
	height: 100%;
	opacity: .25;
	z-index: 1;
	mix-blend-mode: multiply;
	width: 100%;
	@include media-breakpoint-up(lg) {
		background-size: 80%;
		top: -40%;
		background-position: top center;
	}
	@include media-breakpoint-down(lg) {
		background-size: 100%;
		left: 25%;
		top: -55%;
		background-position: top center;
	}
	@include media-breakpoint-down(sm) {
		left:0 !important;
		right:0 !important;
	}
}

.shaped-drops {
	position:relative;
	&:not(:last-child):before {
		@extend %drop;
		left: calc(16.6665% + 33.3333%);
	}
	&:first-child:before {
		right:0;
	}
	@include media-breakpoint-down(lg) {
		&:nth-child(even) {
			margin-left:auto;
			&:before {
				display:none;
			}
		}
		&:last-child {
			margin-left:0;
		}
	
	}
}

.shape-drop-down-left {
	&:after {
		background-image: url('../img/bg-drop.svg');
	}
}

.shape-drop-up-right {
	&:after {
		background-image: url('../img/bg-drop-up.svg'); 
		// needed to change the svg to avoid transform rotate; 
		// research why transform rotate creates transparency artifacts in svg??? 
	}
	// needs work to generalize the condition:
	@include media-breakpoint-down(sm) {
		transform: scaleX(-1);
	}
}

.shape-drop-down-right {
	&:after {
		background-image: url('../img/bg-drop.svg');
		transform: rotate(-270deg);
	}
}


// Video Play

.btn-play {
	@extend .btn;
	@extend .btn-link;
	@extend .rounded;
	img {
		width: 56px;
		height: auto;
	}
}

.shaped-drops .btn-play {
	position:absolute; 
	z-index:3; 
}

.shape-drop-down-left .btn-play {
	left: calc(50% - 10% - 28px); 
	top: calc(50% - 10% - 28px);
}


.shape-drop-up-right .btn-play {
	left: calc(50% - 28px); 
	top: calc(50% - 28px);
}


.shape-drop-down-right .btn-play {
	left: calc(50% - 28px); 
	top: calc(50% - 10% - 28px);
}

// // Testimonials

// .testimonial-name {
// 	margin-bottom: 0;
// }

// .testimonial-reason {
// 	@extend .text-opacity-50;
// }

// Carousel
.carousel-indicators {
	margin-bottom: 0;
	position:relative;
	margin-top: 16px;

	[data-bs-target] {
		text-indent: unset;
		width: auto;
		height: auto;
		padding: $carousel-indicator-hit-area-height;
		margin-right: $carousel-indicator-spacer;
		margin-left: $carousel-indicator-spacer;
		text-indent: -999px;
		cursor: pointer;
		background-color: transparent;
		background-clip: padding-box;
		border: 0;
		border-top: 0;
		border-bottom: 0;
		opacity: 1;
		transition: none;

		> span {
			display: block;
			width: $carousel-indicator-width;
			height: $carousel-indicator-height;
			background-clip: padding-box;
			background-color: transparent;
			@extend .border;
			@extend .border-3;
			@extend .border-white;
			@extend .rounded-circle;
			opacity: $carousel-indicator-opacity;
			@include transition($carousel-indicator-transition);
		}
		
		&.active > span {
			opacity: $carousel-indicator-active-opacity;
			background-color: $carousel-indicator-active-bg;
		}
	}
}


// Lists

div.styled-letter-list {
	>div {
		@extend .d-flex;
		margin-bottom: 1.5rem;
		// @extend .position-relative;
	}
	>div>div {
		padding-left: 1.5rem; 
		// @extend .d-inline-block;
	}
	>div>span:first-child {
		width: 2rem;
		height: 2rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-family: serif;
		padding: 1.5rem;
		@extend .lead;
	}
}

.letter-list-dark>div>span:first-child {
	background-color: $charcoal-300;
	@extend .text-white;
}

div.styled-order-list {
	counter-reset: list-number;
}

.styled-order-list {
	div {
		@extend .d-flex;
		margin-bottom: 1.5rem;
		@extend .position-relative;
	}
	div>span {
		padding-left: 3.5rem; 
		@extend .d-inline-block;
	}
	div:before {
		counter-increment: list-number;
		content: counter(list-number);
		width: 2rem;
		height: 2rem;
		@extend .position-absolute;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-family: serif;
		@extend .lead;
	}
}

.order-list-dark div:before {
	background-color: $charcoal-300;
	@extend .text-white;
}

.bullet-list {
	li {
		margin-bottom: $paragraph-margin-bottom;
	}
}

.bullet-list-check {
	//On blue background only; TODO - general/specific
	div {
		@extend .d-flex;
		margin-bottom: 1.5rem;
		@extend .position-relative;
	}
	div>span {
		padding-left: 3.5rem; 
		@extend .d-inline-block;
	}
	div:before {
		background-color: $blue-600;
		content:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewbox='0 0 24 24' fill='white'><path d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' /></svg>");
		width: 32px;
		height: 32px;
		@extend .position-absolute;
		// display: inline-flex;
		// align-items: center;
		// justify-content: center;
		border-radius: 50%;
		@extend .lead;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  // left: 0;
  // right: 0;
  margin: auto;
  text-align: center;
	}
}

.timeline .list-item:not(:last-child) {
	.timeline-item:after {
		content:'';
		position: absolute;
		width: 1px;
		height: 100%;
		left: 0;
		right:0;
		margin-left: auto;
		margin-right: auto;
		background: repeating-linear-gradient(to bottom,transparent 0 4px,#fff 4px 8px),
		linear-gradient(to bottom,$dark,transparent);
		background-size: 1px 100%;
		background-position: 80%;
		background-repeat: no-repeat;
	}
}

.timeline {
	.list-item {
		padding-left: 0;
		padding-right: 0;
	}
}

.timeline-item {
	position:relative;
	margin-right: 1.25rem;
	>* {	
		background-color: $charcoal-300;
		color: $white;
		font-size: $font-size-lg;
		@extend .fw-bold;
		@extend .d-flex;
		@extend .justify-content-center;
		padding: .5rem;
		width: 110px;
		min-width: 110px;
		letter-spacing: .05rem;
	}
}

// MODAL

.large {
	.modal-dialog {
		@extend .modal-lg;
		@extend .modal-dialog-centered;
	}
	.btn-close {
		@extend .btn-close-white;
	}
}


// SECTION

.section {
	@extend .pt-4;
	@extend .pt-sm-5;
	@extend .pt-lg-6;
	@extend .pb-2;
	@extend .pb-lg-4;
	&.bg-* {
		@extend .pt-lg-7;
		@extend .pt-md-5;
		@extend .pt-5;
	}
}

.section-container {
	@extend .container-xl;
	@extend .px-4;
	@extend .px-md-5;
	.row, .card-group {
		@extend .gx-4;
		@extend .gx-md-5;
	}
}

.section-intro {
	 @extend .col-xl-8;
	 @extend .col-lg-10;
	 @extend .mx-auto;
	 @extend .text-center;
	 @extend .mb-4;
	 @extend .mb-lg-6;
}

.section-title {
	@extend .col-lg-8;
	@extend .mx-auto;
	@extend .text-center;
	@extend .pb-4;
	@extend .pb-lg-5;
	h2 {
	margin-bottom: 0;
	}
	.lead, p {
		margin-top:.5rem;
		margin-bottom: 0;
	}
	.lead {
		@include media-breakpoint-down(md) {
			line-height: 1.2;
		}
	}
}

.section-details {
	@extend .col-xl-8;
	@extend .col-lg-10;
	@extend .mx-auto;
	@extend .mb-lg-5;
	@extend .mb-4;
}

.section-card-wide {
	@extend .col-xl-8;
	@extend .col-lg-10;
	@extend .mx-auto;
	@extend .mb-4;
}

.section-card-xl {
	@extend .col-lg-10;
	@extend .mx-auto;
	@extend .mb-4;
}

.section-card-narrow {
	@extend .col-lg-6;
	@extend .col-md-8;
	@extend .mx-auto;
	@extend .mb-4;
}


.cta {
	@extend .col-lg-8;
	@extend .col-12; 
	@extend .mx-auto;
	@extend .text-center;
	@extend .pb-lg-5;
	@extend .pb-4;
	h2 {
		@extend .heading-special;
	}
	.btn {
		@extend .mt-lg-4;
		// @extend .mt-2;
	}
}

.bg-rounded-top {
	@extend .position-relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		background: url("../img/bg-rounded.svg") $white no-repeat;
		background-repeat: no-repeat;
		background-position: top center;
		// background-size: contain;
		background-size: cover;
		width: 100%;
		max-width: 100%;
		height: 180px;
	}
	.container-xl {
		@extend .position-relative;
	}
}

.bg-blue {
	background-color: $blue-500;
}

.bg-charcoal-light {
	background-color: $charcoal-100;
}

.collage-full {
	@extend .pb-0;
	img {
		@extend .img-fluid;
	}
}

.map {
	background: url("../img/map.webp") no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	min-height: 200px;
	@include media-breakpoint-up(md) {
		min-height: 300px;
	}
}

// HERO

header {
	@extend .mb-3;
}

@supports (background-blend-mode: overlay) {
	.bg-hero {
		background-blend-mode: overlay;
		min-height: 300px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@include media-breakpoint-up(md) {
			min-height: 450px;
		}
//TODO: not @supports()
	}
}

.bg-hero {
	@extend .position-relative;
	&:before {
		content: '';
		background-color: $primary;
		@extend .position-absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}

.bg-hero-home {
	&:before {
		background-color: transparent;
	}
}

@mixin media-breakpoint-only-img($name, $breakpoints: $grid-breakpoints) {
	$min:  breakpoint-min($name, $breakpoints);
	$next: breakpoint-next($name, $breakpoints);
	$max:  breakpoint-max($next);

	@if $min != null and $max != null {
		 @media (-webkit-min-device-pixel-ratio: 2) and (min-width: $min),
		(min--moz-device-pixel-ratio: 2)      and (min-width: $min),
		(-o-min-device-pixel-ratio: 2/1)    and (min-width: $min),
		(min-device-pixel-ratio: 2)      and (min-width: $min),
		(min-resolution: 192dpi) and (min-width: $min),
		(min-resolution: 2dppx)  and (min-width: $min) { 
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
		  @content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($next, $breakpoints) {
			@content;
		}
	}
}


@mixin retina-background-image($file, $type, $overlay1, $overlay2, $breakpoints: $grid-breakpoints) {
	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@include media-breakpoint-only($breakpoint, $breakpoints) {
			background-image: url($file + $infix +'.' + $type), $overlay1, $overlay2;
		}
		@include media-breakpoint-only($breakpoint, $breakpoints) {
			background-image: url($file + $infix +'-2x.' + $type), $overlay1, $overlay2;
		}
	}
}

.bg-hero-home-1 {
	@include retina-background-image("../img/carousel/bg-hero-home-1", "webp", $hero-shade-charcoal, $hero-darken);
}

.bg-hero-home-2 {
	@include retina-background-image("../img/carousel/bg-hero-home-2", "webp", $hero-shade-charcoal, $hero-darken);
}

.bg-hero-home-3 {
	@include retina-background-image("../img/carousel/bg-hero-home-3", "webp", $hero-shade-charcoal, $hero-darken);
}

.bg-hero-home-4 {
	@include retina-background-image("../img/carousel/bg-hero-home-4", "webp", $hero-shade-charcoal, $hero-darken);
}

// .bg-hero-home {
// 	@include retina-background-image("../img/bg-hero-home", "webp", $hero-shade-charcoal,  $hero-darken);
// }

.bg-hero-services {
	@include retina-background-image("../img/bg-hero-services", "webp", $hero-shade-dark, $hero-darken);
}

.bg-hero-retreats {
	@include retina-background-image("../img/bg-hero-retreats", "webp", $hero-shade-charcoal, none);
}

.bg-hero-retreats-9-5 {
	@include retina-background-image("../img/bg-hero-retreats-9-5", "webp", $hero-shade-blue, none);
}

.bg-hero-retreats-custom {
	@include retina-background-image("../img/bg-hero-retreats-custom", "webp", $hero-shade-charcoal, $hero-darken);
}

.bg-hero-retreats-immersive {
	@include retina-background-image("../img/bg-hero-retreats-immersive", "webp", $hero-shade-charcoal, none);
}

.bg-hero-coaching {
	@include retina-background-image("../img/bg-hero-coaching", "webp", $hero-shade-charcoal, none);
}

.bg-hero-physio {
	@include retina-background-image("../img/bg-hero-physio", "webp", $hero-shade-charcoal, none);
}

.bg-hero-yoga {
	@include retina-background-image("../img/bg-hero-yoga", "webp", $hero-shade-charcoal, none);
}

.bg-hero-massage {
	@include retina-background-image("../img/bg-hero-massage", "webp", $hero-shade-charcoal, none);
}

.bg-hero-acupuncture {
	@include retina-background-image("../img/bg-hero-acupuncture", "webp", $hero-shade-charcoal, none);
}

.bg-hero-hydro {
	@include retina-background-image("../img/bg-hero-hydro", "webp", $hero-shade-charcoal, none);
}

.bg-hero-beauty {
	@include retina-background-image("../img/bg-hero-beauty", "webp", $hero-shade-charcoal, none);
}

.bg-hero-fitness {
	@include retina-background-image("../img/bg-hero-fitness", "webp", $hero-shade-charcoal, none);
}

.bg-hero-sanctuary {
	@include retina-background-image("../img/bg-hero-sanctuary", "webp", $hero-shade-charcoal, $hero-darken);
}

.bg-hero-philosophy {
	@include retina-background-image("../img/bg-hero-philosophy", "webp", $hero-shade-charcoal, $hero-darken);
}

.bg-hero-coach {
	@include retina-background-image("../img/bg-hero-coach", "webp", $hero-shade-charcoal, $hero-darken);
}

.bg-hero-faq {
	@include retina-background-image("../img/bg-hero-faq", "webp", $hero-shade-charcoal, $hero-darken);
}

.bg-hero-connect {
	@include retina-background-image("../img/bg-hero-connect", "webp", $hero-shade-charcoal, $hero-darken);
}

// .bg-hero-home {
// 	.overtitle {
// 		@extend .text-green-light;
// 	}
// }

.hero-text {
	@extend .pt-lg-6;
	@extend .py-5;
	@extend .text-white;
	.overtitle {
		@extend .text-shadow;
		@extend .fs-3;
		@extend .pb-lg-2;
		font-weight: $font-weight-semibold;
		letter-spacing:.05rem;
	}
	h1 {
		@extend .text-shadow;
		@extend .display-2; 
		// letter-spacing: .05rem;
		font-weight: $font-weight-semibold;
	}
	// .h1:first-letter {
	// 	@include font-size(4.875rem);
	// 	@extend .text-green-light;
	// }
	@include media-breakpoint-down(md) {
		h1 {
			@include font-size(3.5rem);
			line-height: 1.1;
		}
		// .h1:first-letter {
		// 	@include font-size(4rem);
		// }
	}
	.h1 {
		line-height: 1;
	}
	.subtitle {
		@extend .text-shadow;
		@extend .fs-3;
		@extend .d-block;
		@extend .pt-3;
		@extend .pb-4;
		font-weight: $font-weight-semibold;
		@extend .col-xl-4;
		@extend .col-lg-5;
		@extend .col-md-6;
		@extend .col-8;
		line-height: 1.2;
		@include media-breakpoint-down(md) {
			line-height: 1.1;
		}
	}
	p {
		@extend .text-shadow;
	}
}

//MAIN

.bg-main {
	@extend .section;
}

.bg-butterfly {
	background-image: url("../img/bg-main-butterfly.svg");
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: contain;
}

.bg-main-home {
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	margin-top: -10rem;
	padding-top: 10rem !important;
	@include media-breakpoint-up(md) {
		padding-top: 12rem !important;
	}
}

.bg-main-home {
	@include retina-background-image("../img/bg-main-home", "webp", none, none);
}

.bg-main-services {
	@include retina-background-image("../img/bg-main-services", "webp", none, none);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-retreats {
	// @include retina-background-image("../img/bg-main-retreats", "webp", none, none);
	background-image: url("../img/bg-main-retreats.webp");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-retreats-9-5 {
	// @include retina-background-image("../img/bg-main-retreats-9-5", "webp", none, none);
	background-image: url("../img/bg-main-retreats-9-5.webp");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-retreats-custom {
	// @include retina-background-image("../img/bg-main-retreats-custom", "webp", none, none);
	background-image: url("../img/bg-main-retreats-custom.webp");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-retreats-immersive {
	@include retina-background-image("../img/bg-main-retreats-immersive", "webp", none, none);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-coaching {
	// @include retina-background-image("../img/bg-main-coaching", "webp", none, none);
	background-image: url("../img/bg-main-coaching.webp");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-physio {
	// @include retina-background-image("../img/bg-main-physio", "webp", none, none);
		background-image: url("../img/bg-main-physio.webp");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-massage {
	// @include retina-background-image("../img/bg-main-massage", "webp", none, none);
		background-image: url("../img/bg-main-massage.webp");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-philosophy-process {
	@include retina-background-image("../img/bg-main-philosophy-process", "webp", none, none);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-philosophy-people {
	@include retina-background-image("../img/bg-hero-connect", "webp", none, none);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.bg-main-philosophy-principles {
	@include retina-background-image("../img/bg-main-philosophy-principles", "webp", none, none);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

// .bg-main-sanctuary {
// 	background: url("../img/bg-main-sanctuary.webp") no-repeat top center;
// 	background-size: cover;
// }

.video-mask {
	position: relative;
	cursor: pointer;
}


//FOOTER

footer {
	@extend .bg-dark;
	@extend .text-white;
	@extend .py-5;
	font-size: $font-size-sm;
	a {
		@extend .link-light;
	}
}


//VIDEO OVERLAY
.video {
	position: relative;
	display: inline-block;

	/* ie7 inline-block support */
	*display: inline;
	*zoom: 1;
}

.video-cover-home {
	// position: absolute;
	// top: 0px;
	// left: 0px;
	@extend .border;
	@extend .border-white;
	@extend.border-5;
	@extend .shadow;
}

// lite-youtube {
// 	max-width: 100% !important;
// }

// Credit: Nicolas Gallagher and SUIT CSS.

.ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: var(--#{$variable-prefix}aspect-ratio);
    content: "";
  }

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

> img {
	position: absolute;
    top: 0;
    left: 0;
}

@each $key, $ratio in $aspect-ratios {
  .ratio-#{$key} {
    --#{$variable-prefix}aspect-ratio: #{$ratio};
  }
}

