//
// Base styles
//

.list {
  position: relative;
  min-width: 0;
  // height: $list-height;
  word-wrap: break-word;
  // background-color: $list-bg;
  // background-clip: border-box;
  border: $list-border-width solid $list-border-color;
  // @include border-radius($list-border-radius);
}

.list-item {
  display: flex;
  padding: $list-spacer-y $list-spacer-x;
  color: $list-color;
  > * {
    // &:not(:last-child) {
    //     margin-right: $list-spacer-x;
    //   }
    // &:last-child {
    //   margin-left: auto;
    // }
    input:first-child {
      margin-right: 0;
    }
  }
}

.list-link {
  &:hover {
    text-decoration: none;
  }
}

.list-center {
  .list-item {
    align-items:center;
  }
}