@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-charcoal: #394A58;
  --bs-umber: #713018;
  --bs-red: #ba3200;
  --bs-yellow: #fffa7c;
  --bs-green: #739600;
  --bs-blue: #71B2C9;
  --bs-white: #fff;
  --bs-gray: #E0E1DD;
  --bs-gray-dark: #70716F;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #394A58;
  --bs-secondary: #713018;
  --bs-success: #739600;
  --bs-info: #71B2C9;
  --bs-warning: #fffa7c;
  --bs-danger: #ba3200;
  --bs-light: #F3F3F1;
  --bs-dark: #28343E;
  --bs-primary-rgb: 57, 74, 88;
  --bs-secondary-rgb: 113, 48, 24;
  --bs-success-rgb: 115, 150, 0;
  --bs-info-rgb: 113, 178, 201;
  --bs-warning-rgb: 255, 250, 124;
  --bs-danger-rgb: 186, 50, 0;
  --bs-light-rgb: 243, 243, 241;
  --bs-dark-rgb: 40, 52, 62;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 40, 52, 62;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Montserrat", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #28343E;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .card h3, .card .h3, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3rem;
  }
}

h2, .h2 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .card h3, .card .h3, .h4 {
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  h4, .card h3, .card .h3, .h4 {
    font-size: 1.35rem;
  }
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #713018;
  text-decoration: underline;
}
a:hover {
  color: #552412;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead, .bullet-list-check div:before, .styled-order-list div:before, div.styled-letter-list > div > span:first-child {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead, .bullet-list-check div:before, .styled-order-list div:before, div.styled-letter-list > div > span:first-child {
    font-size: 1.5rem;
  }
}

.display-1 {
  font-size: calc(1.6125rem + 4.35vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.875rem;
  }
}

.display-2, .hero-text h1, .hero-text .h1 {
  font-size: calc(1.5125rem + 3.15vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2, .hero-text h1, .hero-text .h1 {
    font-size: 3.875rem;
  }
}

.display-3 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 3rem;
  }
}

.display-4 {
  font-size: calc(1.4125rem + 1.95vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.875rem;
  }
}

.display-5 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.5rem;
  }
}

.display-6 {
  font-size: calc(1.35rem + 1.2vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.25rem;
  }
}

.list-unstyled, .navbar-nav .dropdown-wrapper {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.section-container,
.container-section,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 2.5rem);
  padding-left: var(--bs-gutter-x, 2.5rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .section-container, .container-section, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .section-container, .container-section, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row, .card-group {
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > *, .card-group > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8, .hero-text .subtitle {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12, .cta {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4,
.section-container .row,
.section-container .card-group {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6, .card-group-3 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6, .hero-text .subtitle {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8, .section-card-narrow, .card-group-2 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5,
.section-container .row,
.section-container .card-group {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4, .card-group-3 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5, .hero-text .subtitle {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6, .section-card-narrow, .card-group-2 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8, .cta, .section-title {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10, .section-card-xl, .section-card-wide, .section-details, .section-intro {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4, .hero-text .subtitle {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8, .section-card-wide, .section-details, .section-intro {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #4F7D8D;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check[disabled] + .btn-play, .btn-check:disabled + .btn, .btn-check:disabled + .btn-play {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn, .input-group .btn-play {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .btn-play:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .btn-play {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .btn-play {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark, .navbar-nav .dropdown-menu {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item, .navbar-nav .dropdown-menu .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .navbar-nav .dropdown-menu .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus, .navbar-nav .dropdown-menu .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .navbar-nav .dropdown-menu .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active, .navbar-nav .dropdown-menu .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled, .navbar-nav .dropdown-menu .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled, .navbar-nav .dropdown-menu .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider, .navbar-nav .dropdown-menu .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text, .navbar-nav .dropdown-menu .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header, .navbar-nav .dropdown-menu .dropdown-header {
  color: #adb5bd;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link, .navbar-nav .dropdown-toggle {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link, .navbar-nav .dropdown-toggle {
    transition: none;
  }
}
.nav-link:hover, .navbar-nav .dropdown-toggle:hover, .nav-link:focus, .navbar-nav .dropdown-toggle:focus {
  color: #0a58ca;
}
.nav-link.disabled, .navbar-nav .disabled.dropdown-toggle {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link, .nav-tabs .navbar-nav .dropdown-toggle, .navbar-nav .nav-tabs .dropdown-toggle {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .navbar-nav .dropdown-toggle:hover, .navbar-nav .nav-tabs .dropdown-toggle:hover, .nav-tabs .nav-link:focus, .nav-tabs .navbar-nav .dropdown-toggle:focus, .navbar-nav .nav-tabs .dropdown-toggle:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled, .nav-tabs .navbar-nav .disabled.dropdown-toggle, .navbar-nav .nav-tabs .disabled.dropdown-toggle {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active, .nav-tabs .navbar-nav .active.dropdown-toggle, .navbar-nav .nav-tabs .active.dropdown-toggle,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item.show .navbar-nav .dropdown-toggle,
.navbar-nav .nav-tabs .nav-item.show .dropdown-toggle {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link, .nav-pills .navbar-nav .dropdown-toggle, .navbar-nav .nav-pills .dropdown-toggle {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active, .nav-pills .navbar-nav .active.dropdown-toggle, .navbar-nav .nav-pills .active.dropdown-toggle,
.nav-pills .show > .nav-link,
.nav-pills .navbar-nav .show > .dropdown-toggle,
.navbar-nav .nav-pills .show > .dropdown-toggle {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link, .navbar-nav .nav-fill > .dropdown-toggle,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link, .navbar-nav .nav-justified > .dropdown-toggle,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link, .nav-fill .nav-item .navbar-nav .dropdown-toggle, .navbar-nav .nav-fill .nav-item .dropdown-toggle,
.nav-justified .nav-item .nav-link,
.nav-justified .nav-item .navbar-nav .dropdown-toggle,
.navbar-nav .nav-justified .nav-item .dropdown-toggle {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .section-container,
.navbar > .container-section,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link, .navbar-nav .dropdown-toggle {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link, .navbar-expand-sm .navbar-nav .dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link, .navbar-expand-md .navbar-nav .dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link, .navbar-expand-lg .navbar-nav .dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link, .navbar-expand-xl .navbar-nav .dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link, .navbar-expand-xxl .navbar-nav .dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link, .navbar-expand .navbar-nav .dropdown-toggle {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .dropdown-toggle {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .dropdown-toggle:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .dropdown-toggle:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled, .navbar-light .navbar-nav .disabled.dropdown-toggle {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .show > .dropdown-toggle,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .active.dropdown-toggle {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .dropdown-toggle {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .dropdown-toggle:hover, .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .dropdown-toggle:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled, .navbar-dark .navbar-nav .disabled.dropdown-toggle {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .show > .dropdown-toggle,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .active.dropdown-toggle {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: #fff;
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #28343E;
  background-color: #E3F0F4;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #4F7D8D;
  outline: 0;
  box-shadow: none;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #222c35;
  background-color: #d7dbde;
  border-color: #c4c9cd;
}
.alert-primary .alert-link {
  color: #1b232a;
}

.alert-secondary {
  color: #441d0e;
  background-color: #e3d6d1;
  border-color: #d4c1ba;
}
.alert-secondary .alert-link {
  color: #36170b;
}

.alert-success {
  color: #455a00;
  background-color: #e3eacc;
  border-color: #d5e0b3;
}
.alert-success .alert-link {
  color: #374800;
}

.alert-info {
  color: #446b79;
  background-color: #e3f0f4;
  border-color: #d4e8ef;
}
.alert-info .alert-link {
  color: #365661;
}

.alert-warning {
  color: #666432;
  background-color: #fffee5;
  border-color: #fffed8;
}
.alert-warning .alert-link {
  color: #525028;
}

.alert-danger {
  color: #701e00;
  background-color: #f1d6cc;
  border-color: #eac2b3;
}
.alert-danger .alert-link {
  color: #5a1800;
}

.alert-light {
  color: #616160;
  background-color: #fdfdfc;
  border-color: #fbfbfb;
}
.alert-light .alert-link {
  color: #4e4e4d;
}

.alert-dark {
  color: #181f25;
  background-color: #d4d6d8;
  border-color: #bfc2c5;
}
.alert-dark .alert-link {
  color: #13191e;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #222c35;
  background-color: #d7dbde;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #222c35;
  background-color: #c2c5c8;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #222c35;
  border-color: #222c35;
}

.list-group-item-secondary {
  color: #441d0e;
  background-color: #e3d6d1;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #441d0e;
  background-color: #ccc1bc;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #441d0e;
  border-color: #441d0e;
}

.list-group-item-success {
  color: #455a00;
  background-color: #e3eacc;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #455a00;
  background-color: #ccd3b8;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #455a00;
  border-color: #455a00;
}

.list-group-item-info {
  color: #446b79;
  background-color: #e3f0f4;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #446b79;
  background-color: #ccd8dc;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #446b79;
  border-color: #446b79;
}

.list-group-item-warning {
  color: #666432;
  background-color: #fffee5;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #666432;
  background-color: #e6e5ce;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #666432;
  border-color: #666432;
}

.list-group-item-danger {
  color: #701e00;
  background-color: #f1d6cc;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #701e00;
  background-color: #d9c1b8;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #701e00;
  border-color: #701e00;
}

.list-group-item-light {
  color: #616160;
  background-color: #fdfdfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #616160;
  background-color: #e4e4e3;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #616160;
  border-color: #616160;
}

.list-group-item-dark {
  color: #181f25;
  background-color: #d4d6d8;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #181f25;
  background-color: #bfc1c2;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #181f25;
  border-color: #181f25;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 1em 1em;
  color: #28343E;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2328343E'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.625rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #28343E;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white, .large .btn-close, .btn-close, .offcanvas-header .btn-close, .modal-header .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered, .large .modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #222C35;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #28343E;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.75;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0 0.75rem;
  margin: 0 -0.75rem 0 auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered, .large .modal-dialog {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .large .modal-dialog,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.9s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.9s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  opacity: 0.25;
  transition: opacity, background 0.7s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #394A58;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #394A58;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 300px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 300px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.btn, .btn-play {
  display: inline-block;
  font-weight: 500;
  line-height: initial;
  color: #28343E;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .btn-play {
    transition: none;
  }
}
.btn:hover, .btn-play:hover {
  color: #28343E;
}
.btn-check:focus + .btn, .btn-check:focus + .btn-play, .btn:focus, .btn-play:focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled, .btn-play:disabled, .btn.disabled, .disabled.btn-play, fieldset:disabled .btn, fieldset:disabled .btn-play {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #394A58;
  border-color: #394A58;
}
.btn-primary:hover {
  color: #fff;
  background-color: #303f4b;
  border-color: #2e3b46;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #303f4b;
  border-color: #2e3b46;
  box-shadow: 0 0 0 0.25rem rgba(87, 101, 113, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2e3b46;
  border-color: #2b3842;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(87, 101, 113, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #394A58;
  border-color: #394A58;
}

.btn-secondary {
  color: #fff;
  background-color: #713018;
  border-color: #713018;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #602914;
  border-color: #5a2613;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #602914;
  border-color: #5a2613;
  box-shadow: 0 0 0 0.25rem rgba(134, 79, 59, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5a2613;
  border-color: #552412;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(134, 79, 59, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #713018;
  border-color: #713018;
}

.btn-success {
  color: #000;
  background-color: #739600;
  border-color: #739600;
}
.btn-success:hover {
  color: #000;
  background-color: #88a626;
  border-color: #81a11a;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #88a626;
  border-color: #81a11a;
  box-shadow: 0 0 0 0.25rem rgba(98, 128, 0, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #8fab33;
  border-color: #81a11a;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(98, 128, 0, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #739600;
  border-color: #739600;
}

.btn-info {
  color: #000;
  background-color: #71B2C9;
  border-color: #71B2C9;
}
.btn-info:hover {
  color: #000;
  background-color: #86bed1;
  border-color: #7fbace;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #86bed1;
  border-color: #7fbace;
  box-shadow: 0 0 0 0.25rem rgba(96, 151, 171, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #8dc1d4;
  border-color: #7fbace;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(96, 151, 171, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #71B2C9;
  border-color: #71B2C9;
}

.btn-warning {
  color: #000;
  background-color: #fffa7c;
  border-color: #fffa7c;
}
.btn-warning:hover {
  color: #000;
  background-color: #fffb90;
  border-color: #fffb89;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #fffb90;
  border-color: #fffb89;
  box-shadow: 0 0 0 0.25rem rgba(217, 213, 105, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #fffb96;
  border-color: #fffb89;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 213, 105, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #fffa7c;
  border-color: #fffa7c;
}

.btn-danger {
  color: #fff;
  background-color: #ba3200;
  border-color: #ba3200;
}
.btn-danger:hover {
  color: #fff;
  background-color: #9e2b00;
  border-color: #952800;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #9e2b00;
  border-color: #952800;
  box-shadow: 0 0 0 0.25rem rgba(196, 81, 38, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #952800;
  border-color: #8c2600;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(196, 81, 38, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #ba3200;
  border-color: #ba3200;
}

.btn-light {
  color: #000;
  background-color: #F3F3F1;
  border-color: #F3F3F1;
}
.btn-light:hover {
  color: #000;
  background-color: #f5f5f3;
  border-color: #f4f4f2;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f5f5f3;
  border-color: #f4f4f2;
  box-shadow: 0 0 0 0.25rem rgba(207, 207, 205, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f5f5f4;
  border-color: #f4f4f2;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(207, 207, 205, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #F3F3F1;
  border-color: #F3F3F1;
}

.btn-dark {
  color: #fff;
  background-color: #28343E;
  border-color: #28343E;
}
.btn-dark:hover {
  color: #fff;
  background-color: #222c35;
  border-color: #202a32;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #222c35;
  border-color: #202a32;
  box-shadow: 0 0 0 0.25rem rgba(72, 82, 91, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #202a32;
  border-color: #1e272f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(72, 82, 91, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #28343E;
  border-color: #28343E;
}

.btn-outline-primary {
  color: #394A58;
  border-color: #394A58;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #394A58;
  border-color: #394A58;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(57, 74, 88, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #394A58;
  border-color: #394A58;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(57, 74, 88, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #394A58;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #713018;
  border-color: #713018;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #713018;
  border-color: #713018;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(113, 48, 24, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #713018;
  border-color: #713018;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(113, 48, 24, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #713018;
  background-color: transparent;
}

.btn-outline-success {
  color: #739600;
  border-color: #739600;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #739600;
  border-color: #739600;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(115, 150, 0, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #739600;
  border-color: #739600;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(115, 150, 0, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #739600;
  background-color: transparent;
}

.btn-outline-info {
  color: #71B2C9;
  border-color: #71B2C9;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #71B2C9;
  border-color: #71B2C9;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(113, 178, 201, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #71B2C9;
  border-color: #71B2C9;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(113, 178, 201, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #71B2C9;
  background-color: transparent;
}

.btn-outline-warning {
  color: #fffa7c;
  border-color: #fffa7c;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #fffa7c;
  border-color: #fffa7c;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 250, 124, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #fffa7c;
  border-color: #fffa7c;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 250, 124, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #fffa7c;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ba3200;
  border-color: #ba3200;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ba3200;
  border-color: #ba3200;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(186, 50, 0, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #ba3200;
  border-color: #ba3200;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(186, 50, 0, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #ba3200;
  background-color: transparent;
}

.btn-outline-light {
  color: #F3F3F1;
  border-color: #F3F3F1;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #F3F3F1;
  border-color: #F3F3F1;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(243, 243, 241, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #F3F3F1;
  border-color: #F3F3F1;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(243, 243, 241, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #F3F3F1;
  background-color: transparent;
}

.btn-outline-dark {
  color: #28343E;
  border-color: #28343E;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #28343E;
  border-color: #28343E;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 52, 62, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #28343E;
  border-color: #28343E;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 52, 62, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #28343E;
  background-color: transparent;
}

.btn-link, .btn-play {
  font-weight: 400;
  color: #713018;
  text-decoration: underline;
}
.btn-link:hover, .btn-play:hover {
  color: #0a58ca;
}
.btn-link:disabled, .btn-play:disabled, .btn-link.disabled, .disabled.btn-play {
  color: #6c757d;
}

.btn-lg {
  padding: 0.75rem 1.125rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.img-fluid, .collage-full img, .card-img {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.media-fit {
  width: 100%;
  height: 100%;
}

.media-fit-fill {
  -o-object-fit: fill;
  object-fit: fill;
}

.media-fit-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.media-fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.media-fit-scaled {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.media-center {
  -o-object-position: center;
  object-position: center;
}

.media-center-top {
  -o-object-position: center top;
  object-position: center top;
}

.media-left-top {
  -o-object-position: left top;
  object-position: left top;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: 1.5rem 1.5rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-primary {
  color: #fff;
  background-color: #394A58;
  border-color: #394A58;
}

.card-secondary {
  color: #fff;
  background-color: #713018;
  border-color: #713018;
}

.card-success {
  color: #000;
  background-color: #739600;
  border-color: #739600;
}

.card-info {
  color: #000;
  background-color: #71B2C9;
  border-color: #71B2C9;
}

.card-warning {
  color: #000;
  background-color: #fffa7c;
  border-color: #fffa7c;
}

.card-danger {
  color: #fff;
  background-color: #ba3200;
  border-color: #ba3200;
}

.card-light {
  color: #000;
  background-color: #F3F3F1;
  border-color: #F3F3F1;
}

.card-dark {
  color: #fff;
  background-color: #28343E;
  border-color: #28343E;
}

.card-transparent {
  mix-blend-mode: hard-light;
}
.card-mix, .card-mix-burn > .card-body:before, .card-mix-transparent > .card-body:before, .card-mix-transparent:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  mix-blend-mode: hard-light;
}

.card-mix-transparent {
  color: #fff;
  position: relative;
}
.card-mix-transparent:before {
  mix-blend-mode: multiply;
  background-color: #28343E;
}
.card-mix-transparent > .card-body:before {
  background-color: rgba(113, 178, 201, 0.35);
}

.card-mix-burn:before {
  mix-blend-mode: color-burn;
  background-color: rgba(40, 52, 62, 0.4);
}
.card-mix-burn > .card-body:before {
  background-color: rgba(0, 156, 215, 0.1);
}

.card-lg-x .card-footer, .card-lg .card-footer {
  padding-left: 2rem;
  padding-right: 2rem;
}
.card-lg-x .card-body, .card-lg .card-body {
  padding-left: 2rem;
  padding-right: 2rem;
}
.card-lg-x .card-header, .card-lg .card-header {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 768px) {
  .card-lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.text-shadow, .hero-text p, .hero-text .subtitle, .hero-text h1, .hero-text .h1, .hero-text .overtitle {
  text-shadow: 2px 2px 3px rgba(40, 52, 62, 0.45);
}

.text-green-light {
  color: #C7D599;
}

.lead, .bullet-list-check div:before, .styled-order-list div:before, div.styled-letter-list > div > span:first-child {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .lead, .bullet-list-check div:before, .styled-order-list div:before, div.styled-letter-list > div > span:first-child {
    font-size: 1.2rem;
  }
}

.heading-special, .cta h2, .cta .h2 {
  font-family: "EB Garamond", "Apple Garamond", "Baskerville", Times, Georgia, serif;
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .heading-special, .cta h2, .cta .h2 {
    font-size: 2.5rem;
  }
}

.isolate, .card-transparent > * {
  isolation: isolate;
}

.img-wrapper-stretch {
  width: 200px;
  height: 150px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}

.img-wrapper-stretch > img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
}

.navbar-brand img {
  width: 120px;
  height: 92px;
}
@media (min-width: 576px) {
  .navbar-brand img {
    width: 180px;
    height: 138px;
  }
}

.navbar-nav .nav-link, .navbar-nav .dropdown-toggle {
  font-size: 1.1rem;
  font-weight: 500;
}
.navbar-nav .dropdown-toggle {
  color: #fff;
}
.navbar-nav .dropdown-toggle:after {
  margin-left: 0.5em;
}
.navbar-nav .dropdown-toggle:hover, .navbar-nav .dropdown-toggle:focus {
  color: #fff;
  box-shadow: none;
}
.offcanvas-header {
  padding: 0 1rem;
}

@media (max-width: 991.98px) {
  .nav-item, .dropdown {
    padding-bottom: 0.25rem;
  }
  .nav-item:not(:first-child), .dropdown:not(:first-child) {
    padding-top: 0.25rem;
  }
  .nav-item .btn, .nav-item .btn-play, .dropdown .btn, .dropdown .btn-play {
    text-align: left;
  }
}
.animate-dropdown, .dropdown-menu {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
}
.navbar-toggler {
  align-self: center;
  border-radius: 50%;
  padding: 0.75rem;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.35);
  transition: background-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.nav-list {
  background-color: #f2f0ed;
  padding: 2rem;
}
.nav-list .nav-link, .nav-list .navbar-nav .dropdown-toggle, .navbar-nav .nav-list .dropdown-toggle {
  background: none;
  border: 0;
  border-radius: 0;
  color: #713018;
  font-size: 1.2rem;
  text-align: left;
}
@media (max-width: 575.98px) {
  .nav-list .nav-link, .nav-list .navbar-nav .dropdown-toggle, .navbar-nav .nav-list .dropdown-toggle {
    font-size: 1rem;
  }
}
.nav-list .nav-link:hover, .nav-list .navbar-nav .dropdown-toggle:hover, .navbar-nav .nav-list .dropdown-toggle:hover, .nav-list .nav-link:focus, .nav-list .navbar-nav .dropdown-toggle:focus, .navbar-nav .nav-list .dropdown-toggle:focus {
  color: #552412;
}
.nav-list .nav-link.active, .nav-list .navbar-nav .active.dropdown-toggle, .navbar-nav .nav-list .active.dropdown-toggle,
.nav-list .show > .nav-link,
.nav-list .navbar-nav .show > .dropdown-toggle,
.navbar-nav .nav-list .show > .dropdown-toggle {
  color: #713018;
  background-color: transparent;
  font-weight: bold;
}

.btn-close, .offcanvas-header .btn-close, .modal-header .btn-close {
  border-radius: 50%;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn-close, .offcanvas-header .btn-close, .modal-header .btn-close {
    transition: none;
  }
}
.btn-close:hover, .offcanvas-header .btn-close:hover, .modal-header .btn-close:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.offcanvas-header .btn-close, .modal-header .btn-close {
  margin-top: 1em;
  margin-bottom: 0;
  margin-right: 0;
  padding: 1em 1em;
}

.btn-gray {
  color: #000;
  background-color: #9CA5AC;
  border-color: #9CA5AC;
  color: #fff;
}
.btn-gray:hover {
  color: #000;
  background-color: #abb3b8;
  border-color: #a6aeb4;
}
.btn-check:focus + .btn-gray, .btn-gray:focus {
  color: #000;
  background-color: #abb3b8;
  border-color: #a6aeb4;
  box-shadow: 0 0 0 0.25rem rgba(133, 140, 146, 0.5);
}
.btn-check:checked + .btn-gray, .btn-check:active + .btn-gray, .btn-gray:active, .btn-gray.active, .show > .btn-gray.dropdown-toggle {
  color: #000;
  background-color: #b0b7bd;
  border-color: #a6aeb4;
}
.btn-check:checked + .btn-gray:focus, .btn-check:active + .btn-gray:focus, .btn-gray:active:focus, .btn-gray.active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(133, 140, 146, 0.5);
}
.btn-gray:disabled, .btn-gray.disabled {
  color: #000;
  background-color: #9CA5AC;
  border-color: #9CA5AC;
}

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

h4.card-body, .card-body.h4, .card h3.card-body, h3.card-body, .card-body.h3 {
  margin-bottom: 0;
}

.card-white {
  background-color: white;
  color: #28343E;
}

.shaped {
  position: relative;
}
.shaped:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.shaped-drops:not(:last-child):before {
  content: "";
  position: absolute;
  background-image: url("../img/bg-water.svg");
  background-repeat: no-repeat;
  height: 100%;
  opacity: 0.25;
  z-index: 1;
  mix-blend-mode: multiply;
  width: 100%;
}
@media (min-width: 992px) {
  .shaped-drops:not(:last-child):before {
    background-size: 80%;
    top: -40%;
    background-position: top center;
  }
}
@media (max-width: 991.98px) {
  .shaped-drops:not(:last-child):before {
    background-size: 100%;
    left: 25%;
    top: -55%;
    background-position: top center;
  }
}
@media (max-width: 575.98px) {
  .shaped-drops:not(:last-child):before {
    left: 0 !important;
    right: 0 !important;
  }
}

.shaped-drops {
  position: relative;
}
.shaped-drops:not(:last-child):before {
  left: 49.9998%;
}
.shaped-drops:first-child:before {
  right: 0;
}
@media (max-width: 991.98px) {
  .shaped-drops:nth-child(even) {
    margin-left: auto;
  }
  .shaped-drops:nth-child(even):before {
    display: none;
  }
  .shaped-drops:last-child {
    margin-left: 0;
  }
}

.shape-drop-down-left:after {
  background-image: url("../img/bg-drop.svg");
}

.shape-drop-up-right:after {
  background-image: url("../img/bg-drop-up.svg");
}
@media (max-width: 575.98px) {
  .shape-drop-up-right {
    transform: scaleX(-1);
  }
}

.shape-drop-down-right:after {
  background-image: url("../img/bg-drop.svg");
  transform: rotate(-270deg);
}

.btn-play img {
  width: 56px;
  height: auto;
}

.shaped-drops .btn-play {
  position: absolute;
  z-index: 3;
}

.shape-drop-down-left .btn-play {
  left: calc(40% - 28px);
  top: calc(40% - 28px);
}

.shape-drop-up-right .btn-play {
  left: calc(50% - 28px);
  top: calc(50% - 28px);
}

.shape-drop-down-right .btn-play {
  left: calc(50% - 28px);
  top: calc(40% - 28px);
}

.carousel-indicators {
  margin-bottom: 0;
  position: relative;
  margin-top: 16px;
}
.carousel-indicators [data-bs-target] {
  text-indent: unset;
  width: auto;
  height: auto;
  padding: 16px;
  margin-right: 5px;
  margin-left: 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;
  border-top: 0;
  border-bottom: 0;
  opacity: 1;
  transition: none;
}
.carousel-indicators [data-bs-target] > span {
  display: block;
  width: 24px;
  height: 24px;
  background-clip: padding-box;
  background-color: transparent;
  opacity: 0.25;
  transition: opacity, background 0.7s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] > span {
    transition: none;
  }
}
.carousel-indicators [data-bs-target].active > span {
  opacity: 1;
  background-color: #fff;
}

div.styled-letter-list > div {
  margin-bottom: 1.5rem;
}
div.styled-letter-list > div > div {
  padding-left: 1.5rem;
}
div.styled-letter-list > div > span:first-child {
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: serif;
  padding: 1.5rem;
}

.letter-list-dark > div > span:first-child {
  background-color: #74808A;
}

div.styled-order-list {
  counter-reset: list-number;
}

.styled-order-list div {
  margin-bottom: 1.5rem;
}
.styled-order-list div > span {
  padding-left: 3.5rem;
}
.styled-order-list div:before {
  counter-increment: list-number;
  content: counter(list-number);
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: serif;
}

.order-list-dark div:before {
  background-color: #74808A;
}

.bullet-list li {
  margin-bottom: 1rem;
}

.bullet-list-check div {
  margin-bottom: 1.5rem;
}
.bullet-list-check div > span {
  padding-left: 3.5rem;
}
.bullet-list-check div:before {
  background-color: #4F7D8D;
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewbox='0 0 24 24' fill='white'><path d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' /></svg>");
  width: 32px;
  height: 32px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  text-align: center;
}

.timeline .list-item:not(:last-child) .timeline-item:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: repeating-linear-gradient(to bottom, transparent 0 4px, #fff 4px 8px), linear-gradient(to bottom, #28343E, transparent);
  background-size: 1px 100%;
  background-position: 80%;
  background-repeat: no-repeat;
}

.timeline .list-item {
  padding-left: 0;
  padding-right: 0;
}

.timeline-item {
  position: relative;
  margin-right: 1.25rem;
}
.timeline-item > * {
  background-color: #74808A;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.5rem;
  width: 110px;
  min-width: 110px;
  letter-spacing: 0.05rem;
}

.section-title h2, .section-title .h2 {
  margin-bottom: 0;
}
.section-title .lead, .section-title div.styled-letter-list > div > span:first-child, .section-title .styled-order-list div:before, .styled-order-list .section-title div:before, .section-title .bullet-list-check div:before, .bullet-list-check .section-title div:before, .section-title p {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .section-title .lead, .section-title div.styled-letter-list > div > span:first-child, .section-title .styled-order-list div:before, .styled-order-list .section-title div:before, .section-title .bullet-list-check div:before, .bullet-list-check .section-title div:before {
    line-height: 1.2;
  }
}

.bg-rounded-top:before {
  content: "";
  position: absolute;
  top: 0;
  background: url("../img/bg-rounded.svg") #fff no-repeat;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  width: 100%;
  max-width: 100%;
  height: 180px;
}
.bg-blue {
  background-color: #5A8EA1;
}

.bg-charcoal-light {
  background-color: #D7DBDE;
}

.map {
  background: url("../img/map.webp") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 200px;
}
@media (min-width: 768px) {
  .map {
    min-height: 300px;
  }
}

@supports (background-blend-mode: overlay) {
  .bg-hero {
    background-blend-mode: overlay;
    min-height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .bg-hero {
      min-height: 450px;
    }
  }
}
.bg-hero:before {
  content: "";
  background-color: #394A58;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-hero-home:before {
  background-color: transparent;
}

@media (max-width: 575.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-home-1 {
    background-image: url("../img/carousel/bg-hero-home-1-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-home-2 {
    background-image: url("../img/carousel/bg-hero-home-2-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-home-3 {
    background-image: url("../img/carousel/bg-hero-home-3-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-home-4 {
    background-image: url("../img/carousel/bg-hero-home-4-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-2x.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-sm.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-sm-2x.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-md.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-md-2x.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-lg.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-lg-2x.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-xl.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-xl-2x.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-xxl.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-services {
    background-image: url("../img/bg-hero-services-xxl-2x.webp"), linear-gradient(#222c35 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-retreats {
    background-image: url("../img/bg-hero-retreats-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-2x.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-sm.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-sm-2x.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-md.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-md-2x.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-lg.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-lg-2x.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-xl.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-xl-2x.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-xxl.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-retreats-9-5 {
    background-image: url("../img/bg-hero-retreats-9-5-xxl-2x.webp"), linear-gradient(#003a45 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-retreats-custom {
    background-image: url("../img/bg-hero-retreats-custom-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-retreats-immersive {
    background-image: url("../img/bg-hero-retreats-immersive-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-coaching {
    background-image: url("../img/bg-hero-coaching-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-physio {
    background-image: url("../img/bg-hero-physio-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-yoga {
    background-image: url("../img/bg-hero-yoga-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-massage {
    background-image: url("../img/bg-hero-massage-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-acupuncture {
    background-image: url("../img/bg-hero-acupuncture-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-hydro {
    background-image: url("../img/bg-hero-hydro-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-beauty {
    background-image: url("../img/bg-hero-beauty-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (max-width: 575.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}
@media (min-width: 1400px) {
  .bg-hero-fitness {
    background-image: url("../img/bg-hero-fitness-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), none;
  }
}

@media (max-width: 575.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-sanctuary {
    background-image: url("../img/bg-hero-sanctuary-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-philosophy {
    background-image: url("../img/bg-hero-philosophy-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-coach {
    background-image: url("../img/bg-hero-coach-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-faq {
    background-image: url("../img/bg-hero-faq-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

@media (max-width: 575.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (max-width: 575.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-sm.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-sm-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-md.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-md-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-lg.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-lg-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-xl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-xl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-xxl.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}
@media (min-width: 1400px) {
  .bg-hero-connect {
    background-image: url("../img/bg-hero-connect-xxl-2x.webp"), linear-gradient(rgba(40, 52, 62, 0.85) 0, rgba(255, 255, 255, 0) 25%), linear-gradient(rgba(34, 44, 53, 0.2) 0, rgba(34, 44, 53, 0.1) 100%);
  }
}

.hero-text .overtitle {
  font-weight: 500;
  letter-spacing: 0.05rem;
}
.hero-text h1, .hero-text .h1 {
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .hero-text h1, .hero-text .h1 {
    font-size: calc(1.475rem + 2.7vw);
    line-height: 1.1;
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .hero-text h1, .hero-text .h1 {
    font-size: 3.5rem;
  }
}
.hero-text .h1 {
  line-height: 1;
}
.hero-text .subtitle {
  font-weight: 500;
  line-height: 1.2;
}
@media (max-width: 767.98px) {
  .hero-text .subtitle {
    line-height: 1.1;
  }
}
.bg-butterfly {
  background-image: url("../img/bg-main-butterfly.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
}

.bg-main-home {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin-top: -10rem;
  padding-top: 10rem !important;
}
@media (min-width: 768px) {
  .bg-main-home {
    padding-top: 12rem !important;
  }
}

@media (max-width: 575.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home.webp"), none, none;
  }
}
@media (max-width: 575.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-2x.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-sm.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-sm-2x.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-md.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-md-2x.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-lg.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-lg-2x.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-xl.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-xl-2x.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-xxl.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-home {
    background-image: url("../img/bg-main-home-xxl-2x.webp"), none, none;
  }
}

.bg-main-services {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media (max-width: 575.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services.webp"), none, none;
  }
}
@media (max-width: 575.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-2x.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-sm.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-sm-2x.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-md.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-md-2x.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-lg.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-lg-2x.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-xl.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-xl-2x.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-xxl.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-services {
    background-image: url("../img/bg-main-services-xxl-2x.webp"), none, none;
  }
}

.bg-main-retreats {
  background-image: url("../img/bg-main-retreats.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-main-retreats-9-5 {
  background-image: url("../img/bg-main-retreats-9-5.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-main-retreats-custom {
  background-image: url("../img/bg-main-retreats-custom.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-main-retreats-immersive {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media (max-width: 575.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive.webp"), none, none;
  }
}
@media (max-width: 575.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-2x.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-sm.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-sm-2x.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-md.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-md-2x.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-lg.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-lg-2x.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-xl.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-xl-2x.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-xxl.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-retreats-immersive {
    background-image: url("../img/bg-main-retreats-immersive-xxl-2x.webp"), none, none;
  }
}

.bg-main-coaching {
  background-image: url("../img/bg-main-coaching.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-main-physio {
  background-image: url("../img/bg-main-physio.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-main-massage {
  background-image: url("../img/bg-main-massage.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-main-philosophy-process {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media (max-width: 575.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process.webp"), none, none;
  }
}
@media (max-width: 575.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-2x.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-sm.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-sm-2x.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-md.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-md-2x.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-lg.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-lg-2x.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-xl.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-xl-2x.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-xxl.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-philosophy-process {
    background-image: url("../img/bg-main-philosophy-process-xxl-2x.webp"), none, none;
  }
}

.bg-main-philosophy-people {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media (max-width: 575.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect.webp"), none, none;
  }
}
@media (max-width: 575.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-2x.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-sm.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-sm-2x.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-md.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-md-2x.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-lg.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-lg-2x.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-xl.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-xl-2x.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-xxl.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-philosophy-people {
    background-image: url("../img/bg-hero-connect-xxl-2x.webp"), none, none;
  }
}

.bg-main-philosophy-principles {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media (max-width: 575.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles.webp"), none, none;
  }
}
@media (max-width: 575.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-2x.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-sm.webp"), none, none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-sm-2x.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-md.webp"), none, none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-md-2x.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-lg.webp"), none, none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-lg-2x.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-xl.webp"), none, none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-xl-2x.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-xxl.webp"), none, none;
  }
}
@media (min-width: 1400px) {
  .bg-main-philosophy-principles {
    background-image: url("../img/bg-main-philosophy-principles-xxl-2x.webp"), none, none;
  }
}

.video-mask {
  position: relative;
  cursor: pointer;
}

footer {
  font-size: 0.875rem;
}
.video {
  position: relative;
  display: inline-block;
  /* ie7 inline-block support */
  *display: inline;
  *zoom: 1;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

> img {
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.list {
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  border: 0 solid transparent;
}

.list-item {
  display: flex;
  padding: 1rem 1rem;
}
.list-item > * input:first-child {
  margin-right: 0;
}

.list-link:hover {
  text-decoration: none;
}

.list-center .list-item {
  align-items: center;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #394A58;
}
.link-primary:hover, .link-primary:focus {
  color: #2b3842;
}

.link-secondary {
  color: #713018;
}
.link-secondary:hover, .link-secondary:focus {
  color: #552412;
}

.link-success {
  color: #739600;
}
.link-success:hover, .link-success:focus {
  color: #96b040;
}

.link-info {
  color: #71B2C9;
}
.link-info:hover, .link-info:focus {
  color: #95c5d7;
}

.link-warning {
  color: #fffa7c;
}
.link-warning:hover, .link-warning:focus {
  color: #fffb9d;
}

.link-danger {
  color: #ba3200;
}
.link-danger:hover, .link-danger:focus {
  color: #8c2600;
}

.link-light, .card-mix-transparent a, .card-dark a:not(.btn), footer a {
  color: #F3F3F1;
}
.link-light:hover, .card-mix-transparent a:hover, .card-dark a:hover:not(.btn), footer a:hover, .link-light:focus, .card-mix-transparent a:focus, .card-dark a:focus:not(.btn), footer a:focus {
  color: #f6f6f5;
}

.link-dark {
  color: #28343E;
}
.link-dark:hover, .link-dark:focus {
  color: #1e272f;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block, .styled-order-list div > span, .bullet-list-check div > span {
  display: inline-block;
}

.d-block, .hero-text .subtitle {
  display: block;
}

.d-grid {
  display: grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex, div.styled-letter-list > div, .styled-order-list div, .bullet-list-check div, .timeline-item > * {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow, .video-cover-home {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.position-static {
  position: static;
}

.position-relative, .styled-order-list div, .bullet-list-check div, .bg-rounded-top, .bg-rounded-top .container-xl, .bg-rounded-top .container-section, .bg-rounded-top .section-container, .bg-hero {
  position: relative;
}

.position-absolute, .styled-order-list div:before, .bullet-list-check div:before, .bg-hero:before {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border, .carousel-indicators [data-bs-target] > span, .video-cover-home {
  border: 1px solid #dee2e6;
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: 1px solid #dee2e6;
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: 1px solid #dee2e6;
}

.border-end-0 {
  border-right: 0;
}

.border-bottom, .nav-list .nav-item {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: 1px solid #dee2e6;
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  border-color: #394A58;
}

.border-secondary, .nav-list .nav-item {
  border-color: #713018;
}

.border-success {
  border-color: #739600;
}

.border-info {
  border-color: #71B2C9;
}

.border-warning {
  border-color: #fffa7c;
}

.border-danger {
  border-color: #ba3200;
}

.border-light {
  border-color: #F3F3F1;
}

.border-dark {
  border-color: #28343E;
}

.border-white, .carousel-indicators [data-bs-target] > span, .video-cover-home {
  border-color: #fff;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3, .carousel-indicators [data-bs-target] > span {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5, .video-cover-home {
  border-width: 5px;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1, .navbar-nav {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 0.75rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 3rem;
}

.gap-6 {
  gap: 4rem;
}

.gap-7 {
  gap: 5rem;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end, .navbar-nav {
  justify-content: flex-end;
}

.justify-content-center, .card-group, .timeline-item > * {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.5rem;
}

.m-2 {
  margin: 0.75rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 2rem;
}

.m-5 {
  margin: 3rem;
}

.m-6 {
  margin: 4rem;
}

.m-7 {
  margin: 5rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-2 {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.mx-3 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mx-4 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.mx-5 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.mx-6 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.mx-7 {
  margin-right: 5rem;
  margin-left: 5rem;
}

.mx-auto, .section-intro, .section-title, .section-details, .section-card-wide, .section-card-xl, .section-card-narrow, .cta {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-2 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-6 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-7 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 0.75rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 3rem;
}

.mt-6 {
  margin-top: 4rem;
}

.mt-7 {
  margin-top: 5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 0.5rem;
}

.me-2 {
  margin-right: 0.75rem;
}

.me-3, .btn-row-left a:not(:last-child) {
  margin-right: 1rem;
}

.me-4 {
  margin-right: 2rem;
}

.me-5 {
  margin-right: 3rem;
}

.me-6 {
  margin-right: 4rem;
}

.me-7 {
  margin-right: 5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.75rem;
}

.mb-3, .btn-row .btn, .card .btn, .btn-row .btn-play, .card .btn-play, header {
  margin-bottom: 1rem;
}

.mb-4, .heading-special, .card-group-2, .card-group-3, .section-intro, .section-details, .section-card-wide, .section-card-xl, .section-card-narrow, .cta h2, .cta .h2 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mb-6 {
  margin-bottom: 4rem;
}

.mb-7 {
  margin-bottom: 5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 0.5rem;
}

.ms-2 {
  margin-left: 0.75rem;
}

.ms-3 {
  margin-left: 1rem;
}

.ms-4 {
  margin-left: 2rem;
}

.ms-5 {
  margin-left: 3rem;
}

.ms-6 {
  margin-left: 4rem;
}

.ms-7 {
  margin-left: 5rem;
}

.ms-auto, .btn-close, .offcanvas-header .btn-close, .modal-header .btn-close {
  margin-left: auto;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.5rem;
}

.p-2 {
  padding: 0.75rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 3rem;
}

.p-6 {
  padding: 4rem;
}

.p-7 {
  padding: 5rem;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1, .nav-list .nav-link, .nav-list .navbar-nav .dropdown-toggle, .navbar-nav .nav-list .dropdown-toggle {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.px-2 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.px-3, .container-section {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-4, .section-container {
  padding-right: 2rem;
  padding-left: 2rem;
}

.px-5 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.px-6 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.px-7 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5, .hero-text, footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-7 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 0.75rem;
}

.pt-3, .hero-text .subtitle {
  padding-top: 1rem;
}

.pt-4, .section, .bg-main {
  padding-top: 2rem;
}

.pt-5, .section.bg-*, .bg-*.bg-main {
  padding-top: 3rem;
}

.pt-6 {
  padding-top: 4rem;
}

.pt-7 {
  padding-top: 5rem;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: 0.5rem;
}

.pe-2 {
  padding-right: 0.75rem;
}

.pe-3 {
  padding-right: 1rem;
}

.pe-4 {
  padding-right: 2rem;
}

.pe-5 {
  padding-right: 3rem;
}

.pe-6 {
  padding-right: 4rem;
}

.pe-7 {
  padding-right: 5rem;
}

.pb-0, .collage-full {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2, .section, .bg-main {
  padding-bottom: 0.75rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4, .section-title, .cta, .hero-text .subtitle {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.pb-6 {
  padding-bottom: 4rem;
}

.pb-7 {
  padding-bottom: 5rem;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: 0.5rem;
}

.ps-2 {
  padding-left: 0.75rem;
}

.ps-3 {
  padding-left: 1rem;
}

.ps-4 {
  padding-left: 2rem;
}

.ps-5 {
  padding-left: 3rem;
}

.ps-6 {
  padding-left: 4rem;
}

.ps-7 {
  padding-left: 5rem;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.425rem + 2.1vw);
}

.fs-2 {
  font-size: calc(1.35rem + 1.2vw);
}

.fs-3, .hero-text .overtitle, .hero-text .subtitle {
  font-size: calc(1.3rem + 0.6vw);
}

.fs-4 {
  font-size: calc(1.26rem + 0.12vw);
}

.fs-5 {
  font-size: 1.125rem;
}

.fs-6 {
  font-size: 1rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-light {
  font-weight: 300;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-normal {
  font-weight: 400;
}

.fw-bold, .timeline-item > * {
  font-weight: 700;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center, .section-intro, .section-title, .cta {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-white, .letter-list-dark > div > span:first-child, .order-list-dark div:before, .hero-text, footer {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.bg-dark, footer {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
}

.user-select-auto {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded, .btn-play {
  border-radius: 0.625rem;
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: 0.25rem;
}

.rounded-2 {
  border-radius: 0.625rem;
}

.rounded-3 {
  border-radius: 0.75rem;
}

.rounded-circle, .carousel-indicators [data-bs-target] > span {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 50rem;
}

.rounded-top {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.rounded-end {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.rounded-bottom {
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.rounded-start {
  border-bottom-left-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }

  .float-sm-end {
    float: right;
  }

  .float-sm-none {
    float: none;
  }

  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-grid {
    display: grid;
  }

  .d-sm-table {
    display: table;
  }

  .d-sm-table-row {
    display: table-row;
  }

  .d-sm-table-cell {
    display: table-cell;
  }

  .d-sm-flex {
    display: flex;
  }

  .d-sm-inline-flex {
    display: inline-flex;
  }

  .d-sm-none {
    display: none;
  }

  .flex-sm-fill {
    flex: 1 1 auto;
  }

  .flex-sm-row {
    flex-direction: row;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-sm-grow-0 {
    flex-grow: 0;
  }

  .flex-sm-grow-1 {
    flex-grow: 1;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .gap-sm-0 {
    gap: 0;
  }

  .gap-sm-1 {
    gap: 0.5rem;
  }

  .gap-sm-2 {
    gap: 0.75rem;
  }

  .gap-sm-3 {
    gap: 1rem;
  }

  .gap-sm-4 {
    gap: 2rem;
  }

  .gap-sm-5 {
    gap: 3rem;
  }

  .gap-sm-6 {
    gap: 4rem;
  }

  .gap-sm-7 {
    gap: 5rem;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }

  .justify-content-sm-end {
    justify-content: flex-end;
  }

  .justify-content-sm-center {
    justify-content: center;
  }

  .justify-content-sm-between {
    justify-content: space-between;
  }

  .justify-content-sm-around {
    justify-content: space-around;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }

  .align-items-sm-start {
    align-items: flex-start;
  }

  .align-items-sm-end {
    align-items: flex-end;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .align-items-sm-baseline {
    align-items: baseline;
  }

  .align-items-sm-stretch {
    align-items: stretch;
  }

  .align-content-sm-start {
    align-content: flex-start;
  }

  .align-content-sm-end {
    align-content: flex-end;
  }

  .align-content-sm-center {
    align-content: center;
  }

  .align-content-sm-between {
    align-content: space-between;
  }

  .align-content-sm-around {
    align-content: space-around;
  }

  .align-content-sm-stretch {
    align-content: stretch;
  }

  .align-self-sm-auto {
    align-self: auto;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .align-self-sm-end {
    align-self: flex-end;
  }

  .align-self-sm-center {
    align-self: center;
  }

  .align-self-sm-baseline {
    align-self: baseline;
  }

  .align-self-sm-stretch {
    align-self: stretch;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-last {
    order: 6;
  }

  .m-sm-0 {
    margin: 0;
  }

  .m-sm-1 {
    margin: 0.5rem;
  }

  .m-sm-2 {
    margin: 0.75rem;
  }

  .m-sm-3 {
    margin: 1rem;
  }

  .m-sm-4 {
    margin: 2rem;
  }

  .m-sm-5 {
    margin: 3rem;
  }

  .m-sm-6 {
    margin: 4rem;
  }

  .m-sm-7 {
    margin: 5rem;
  }

  .m-sm-auto {
    margin: auto;
  }

  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-sm-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .mx-sm-2 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }

  .mx-sm-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .mx-sm-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .mx-sm-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .mx-sm-6 {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .mx-sm-7 {
    margin-right: 5rem;
    margin-left: 5rem;
  }

  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-sm-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-sm-2 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .my-sm-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-sm-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .my-sm-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-sm-6 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .my-sm-7 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-sm-0 {
    margin-top: 0;
  }

  .mt-sm-1 {
    margin-top: 0.5rem;
  }

  .mt-sm-2 {
    margin-top: 0.75rem;
  }

  .mt-sm-3 {
    margin-top: 1rem;
  }

  .mt-sm-4 {
    margin-top: 2rem;
  }

  .mt-sm-5 {
    margin-top: 3rem;
  }

  .mt-sm-6 {
    margin-top: 4rem;
  }

  .mt-sm-7 {
    margin-top: 5rem;
  }

  .mt-sm-auto {
    margin-top: auto;
  }

  .me-sm-0 {
    margin-right: 0;
  }

  .me-sm-1 {
    margin-right: 0.5rem;
  }

  .me-sm-2 {
    margin-right: 0.75rem;
  }

  .me-sm-3 {
    margin-right: 1rem;
  }

  .me-sm-4 {
    margin-right: 2rem;
  }

  .me-sm-5 {
    margin-right: 3rem;
  }

  .me-sm-6 {
    margin-right: 4rem;
  }

  .me-sm-7 {
    margin-right: 5rem;
  }

  .me-sm-auto {
    margin-right: auto;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  .mb-sm-1 {
    margin-bottom: 0.5rem;
  }

  .mb-sm-2 {
    margin-bottom: 0.75rem;
  }

  .mb-sm-3 {
    margin-bottom: 1rem;
  }

  .mb-sm-4 {
    margin-bottom: 2rem;
  }

  .mb-sm-5, .card-group-3 {
    margin-bottom: 3rem;
  }

  .mb-sm-6 {
    margin-bottom: 4rem;
  }

  .mb-sm-7 {
    margin-bottom: 5rem;
  }

  .mb-sm-auto {
    margin-bottom: auto;
  }

  .ms-sm-0 {
    margin-left: 0;
  }

  .ms-sm-1 {
    margin-left: 0.5rem;
  }

  .ms-sm-2 {
    margin-left: 0.75rem;
  }

  .ms-sm-3 {
    margin-left: 1rem;
  }

  .ms-sm-4 {
    margin-left: 2rem;
  }

  .ms-sm-5 {
    margin-left: 3rem;
  }

  .ms-sm-6 {
    margin-left: 4rem;
  }

  .ms-sm-7 {
    margin-left: 5rem;
  }

  .ms-sm-auto {
    margin-left: auto;
  }

  .p-sm-0 {
    padding: 0;
  }

  .p-sm-1 {
    padding: 0.5rem;
  }

  .p-sm-2 {
    padding: 0.75rem;
  }

  .p-sm-3 {
    padding: 1rem;
  }

  .p-sm-4 {
    padding: 2rem;
  }

  .p-sm-5 {
    padding: 3rem;
  }

  .p-sm-6 {
    padding: 4rem;
  }

  .p-sm-7 {
    padding: 5rem;
  }

  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-sm-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .px-sm-2 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .px-sm-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .px-sm-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .px-sm-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .px-sm-6 {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .px-sm-7 {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-sm-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-sm-2 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .py-sm-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-sm-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .py-sm-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .py-sm-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .py-sm-7 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .pt-sm-0 {
    padding-top: 0;
  }

  .pt-sm-1 {
    padding-top: 0.5rem;
  }

  .pt-sm-2 {
    padding-top: 0.75rem;
  }

  .pt-sm-3 {
    padding-top: 1rem;
  }

  .pt-sm-4 {
    padding-top: 2rem;
  }

  .pt-sm-5, .section, .bg-main {
    padding-top: 3rem;
  }

  .pt-sm-6 {
    padding-top: 4rem;
  }

  .pt-sm-7 {
    padding-top: 5rem;
  }

  .pe-sm-0 {
    padding-right: 0;
  }

  .pe-sm-1 {
    padding-right: 0.5rem;
  }

  .pe-sm-2 {
    padding-right: 0.75rem;
  }

  .pe-sm-3 {
    padding-right: 1rem;
  }

  .pe-sm-4 {
    padding-right: 2rem;
  }

  .pe-sm-5 {
    padding-right: 3rem;
  }

  .pe-sm-6 {
    padding-right: 4rem;
  }

  .pe-sm-7 {
    padding-right: 5rem;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .pb-sm-1 {
    padding-bottom: 0.5rem;
  }

  .pb-sm-2 {
    padding-bottom: 0.75rem;
  }

  .pb-sm-3 {
    padding-bottom: 1rem;
  }

  .pb-sm-4 {
    padding-bottom: 2rem;
  }

  .pb-sm-5 {
    padding-bottom: 3rem;
  }

  .pb-sm-6 {
    padding-bottom: 4rem;
  }

  .pb-sm-7 {
    padding-bottom: 5rem;
  }

  .ps-sm-0 {
    padding-left: 0;
  }

  .ps-sm-1 {
    padding-left: 0.5rem;
  }

  .ps-sm-2 {
    padding-left: 0.75rem;
  }

  .ps-sm-3 {
    padding-left: 1rem;
  }

  .ps-sm-4 {
    padding-left: 2rem;
  }

  .ps-sm-5 {
    padding-left: 3rem;
  }

  .ps-sm-6 {
    padding-left: 4rem;
  }

  .ps-sm-7 {
    padding-left: 5rem;
  }

  .text-sm-start {
    text-align: left;
  }

  .text-sm-end {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }

  .float-md-end {
    float: right;
  }

  .float-md-none {
    float: none;
  }

  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-grid {
    display: grid;
  }

  .d-md-table {
    display: table;
  }

  .d-md-table-row {
    display: table-row;
  }

  .d-md-table-cell {
    display: table-cell;
  }

  .d-md-flex {
    display: flex;
  }

  .d-md-inline-flex {
    display: inline-flex;
  }

  .d-md-none {
    display: none;
  }

  .flex-md-fill {
    flex: 1 1 auto;
  }

  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-column {
    flex-direction: column;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-md-grow-0 {
    flex-grow: 0;
  }

  .flex-md-grow-1 {
    flex-grow: 1;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .gap-md-0 {
    gap: 0;
  }

  .gap-md-1 {
    gap: 0.5rem;
  }

  .gap-md-2 {
    gap: 0.75rem;
  }

  .gap-md-3 {
    gap: 1rem;
  }

  .gap-md-4 {
    gap: 2rem;
  }

  .gap-md-5 {
    gap: 3rem;
  }

  .gap-md-6 {
    gap: 4rem;
  }

  .gap-md-7 {
    gap: 5rem;
  }

  .justify-content-md-start {
    justify-content: flex-start;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .justify-content-md-between {
    justify-content: space-between;
  }

  .justify-content-md-around {
    justify-content: space-around;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly;
  }

  .align-items-md-start {
    align-items: flex-start;
  }

  .align-items-md-end {
    align-items: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-items-md-baseline {
    align-items: baseline;
  }

  .align-items-md-stretch {
    align-items: stretch;
  }

  .align-content-md-start {
    align-content: flex-start;
  }

  .align-content-md-end {
    align-content: flex-end;
  }

  .align-content-md-center {
    align-content: center;
  }

  .align-content-md-between {
    align-content: space-between;
  }

  .align-content-md-around {
    align-content: space-around;
  }

  .align-content-md-stretch {
    align-content: stretch;
  }

  .align-self-md-auto {
    align-self: auto;
  }

  .align-self-md-start {
    align-self: flex-start;
  }

  .align-self-md-end {
    align-self: flex-end;
  }

  .align-self-md-center {
    align-self: center;
  }

  .align-self-md-baseline {
    align-self: baseline;
  }

  .align-self-md-stretch {
    align-self: stretch;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-last {
    order: 6;
  }

  .m-md-0 {
    margin: 0;
  }

  .m-md-1 {
    margin: 0.5rem;
  }

  .m-md-2 {
    margin: 0.75rem;
  }

  .m-md-3 {
    margin: 1rem;
  }

  .m-md-4 {
    margin: 2rem;
  }

  .m-md-5 {
    margin: 3rem;
  }

  .m-md-6 {
    margin: 4rem;
  }

  .m-md-7 {
    margin: 5rem;
  }

  .m-md-auto {
    margin: auto;
  }

  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-md-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .mx-md-2 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }

  .mx-md-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .mx-md-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .mx-md-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .mx-md-6 {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .mx-md-7 {
    margin-right: 5rem;
    margin-left: 5rem;
  }

  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-md-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-md-2 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .my-md-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-md-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .my-md-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-md-6 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .my-md-7 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .mt-md-1 {
    margin-top: 0.5rem;
  }

  .mt-md-2 {
    margin-top: 0.75rem;
  }

  .mt-md-3 {
    margin-top: 1rem;
  }

  .mt-md-4 {
    margin-top: 2rem;
  }

  .mt-md-5 {
    margin-top: 3rem;
  }

  .mt-md-6 {
    margin-top: 4rem;
  }

  .mt-md-7 {
    margin-top: 5rem;
  }

  .mt-md-auto {
    margin-top: auto;
  }

  .me-md-0 {
    margin-right: 0;
  }

  .me-md-1 {
    margin-right: 0.5rem;
  }

  .me-md-2 {
    margin-right: 0.75rem;
  }

  .me-md-3 {
    margin-right: 1rem;
  }

  .me-md-4 {
    margin-right: 2rem;
  }

  .me-md-5 {
    margin-right: 3rem;
  }

  .me-md-6 {
    margin-right: 4rem;
  }

  .me-md-7 {
    margin-right: 5rem;
  }

  .me-md-auto {
    margin-right: auto;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }

  .mb-md-1 {
    margin-bottom: 0.5rem;
  }

  .mb-md-2 {
    margin-bottom: 0.75rem;
  }

  .mb-md-3 {
    margin-bottom: 1rem;
  }

  .mb-md-4 {
    margin-bottom: 2rem;
  }

  .mb-md-5, .card-group-2 {
    margin-bottom: 3rem;
  }

  .mb-md-6 {
    margin-bottom: 4rem;
  }

  .mb-md-7 {
    margin-bottom: 5rem;
  }

  .mb-md-auto {
    margin-bottom: auto;
  }

  .ms-md-0 {
    margin-left: 0;
  }

  .ms-md-1 {
    margin-left: 0.5rem;
  }

  .ms-md-2 {
    margin-left: 0.75rem;
  }

  .ms-md-3 {
    margin-left: 1rem;
  }

  .ms-md-4 {
    margin-left: 2rem;
  }

  .ms-md-5 {
    margin-left: 3rem;
  }

  .ms-md-6 {
    margin-left: 4rem;
  }

  .ms-md-7 {
    margin-left: 5rem;
  }

  .ms-md-auto {
    margin-left: auto;
  }

  .p-md-0 {
    padding: 0;
  }

  .p-md-1 {
    padding: 0.5rem;
  }

  .p-md-2 {
    padding: 0.75rem;
  }

  .p-md-3 {
    padding: 1rem;
  }

  .p-md-4 {
    padding: 2rem;
  }

  .p-md-5 {
    padding: 3rem;
  }

  .p-md-6 {
    padding: 4rem;
  }

  .p-md-7 {
    padding: 5rem;
  }

  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-md-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .px-md-2 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .px-md-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .px-md-4, .container-section {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .px-md-5, .section-container {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .px-md-6 {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .px-md-7 {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-md-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-md-2 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .py-md-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-md-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .py-md-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .py-md-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .py-md-7 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .pt-md-0 {
    padding-top: 0;
  }

  .pt-md-1 {
    padding-top: 0.5rem;
  }

  .pt-md-2 {
    padding-top: 0.75rem;
  }

  .pt-md-3 {
    padding-top: 1rem;
  }

  .pt-md-4 {
    padding-top: 2rem;
  }

  .pt-md-5, .section.bg-*, .bg-*.bg-main {
    padding-top: 3rem;
  }

  .pt-md-6 {
    padding-top: 4rem;
  }

  .pt-md-7 {
    padding-top: 5rem;
  }

  .pe-md-0 {
    padding-right: 0;
  }

  .pe-md-1 {
    padding-right: 0.5rem;
  }

  .pe-md-2 {
    padding-right: 0.75rem;
  }

  .pe-md-3 {
    padding-right: 1rem;
  }

  .pe-md-4 {
    padding-right: 2rem;
  }

  .pe-md-5 {
    padding-right: 3rem;
  }

  .pe-md-6 {
    padding-right: 4rem;
  }

  .pe-md-7 {
    padding-right: 5rem;
  }

  .pb-md-0 {
    padding-bottom: 0;
  }

  .pb-md-1 {
    padding-bottom: 0.5rem;
  }

  .pb-md-2 {
    padding-bottom: 0.75rem;
  }

  .pb-md-3 {
    padding-bottom: 1rem;
  }

  .pb-md-4 {
    padding-bottom: 2rem;
  }

  .pb-md-5 {
    padding-bottom: 3rem;
  }

  .pb-md-6 {
    padding-bottom: 4rem;
  }

  .pb-md-7 {
    padding-bottom: 5rem;
  }

  .ps-md-0 {
    padding-left: 0;
  }

  .ps-md-1 {
    padding-left: 0.5rem;
  }

  .ps-md-2 {
    padding-left: 0.75rem;
  }

  .ps-md-3 {
    padding-left: 1rem;
  }

  .ps-md-4 {
    padding-left: 2rem;
  }

  .ps-md-5 {
    padding-left: 3rem;
  }

  .ps-md-6 {
    padding-left: 4rem;
  }

  .ps-md-7 {
    padding-left: 5rem;
  }

  .text-md-start {
    text-align: left;
  }

  .text-md-end {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }

  .float-lg-end {
    float: right;
  }

  .float-lg-none {
    float: none;
  }

  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-grid {
    display: grid;
  }

  .d-lg-table {
    display: table;
  }

  .d-lg-table-row {
    display: table-row;
  }

  .d-lg-table-cell {
    display: table-cell;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-inline-flex {
    display: inline-flex;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-fill {
    flex: 1 1 auto;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-lg-grow-0 {
    flex-grow: 0;
  }

  .flex-lg-grow-1 {
    flex-grow: 1;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }

  .flex-lg-wrap {
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .gap-lg-0 {
    gap: 0;
  }

  .gap-lg-1 {
    gap: 0.5rem;
  }

  .gap-lg-2 {
    gap: 0.75rem;
  }

  .gap-lg-3 {
    gap: 1rem;
  }

  .gap-lg-4 {
    gap: 2rem;
  }

  .gap-lg-5 {
    gap: 3rem;
  }

  .gap-lg-6 {
    gap: 4rem;
  }

  .gap-lg-7 {
    gap: 5rem;
  }

  .justify-content-lg-start {
    justify-content: flex-start;
  }

  .justify-content-lg-end {
    justify-content: flex-end;
  }

  .justify-content-lg-center {
    justify-content: center;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .justify-content-lg-around {
    justify-content: space-around;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }

  .align-items-lg-start {
    align-items: flex-start;
  }

  .align-items-lg-end {
    align-items: flex-end;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .align-items-lg-baseline {
    align-items: baseline;
  }

  .align-items-lg-stretch {
    align-items: stretch;
  }

  .align-content-lg-start {
    align-content: flex-start;
  }

  .align-content-lg-end {
    align-content: flex-end;
  }

  .align-content-lg-center {
    align-content: center;
  }

  .align-content-lg-between {
    align-content: space-between;
  }

  .align-content-lg-around {
    align-content: space-around;
  }

  .align-content-lg-stretch {
    align-content: stretch;
  }

  .align-self-lg-auto {
    align-self: auto;
  }

  .align-self-lg-start {
    align-self: flex-start;
  }

  .align-self-lg-end {
    align-self: flex-end;
  }

  .align-self-lg-center {
    align-self: center;
  }

  .align-self-lg-baseline {
    align-self: baseline;
  }

  .align-self-lg-stretch {
    align-self: stretch;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-last {
    order: 6;
  }

  .m-lg-0 {
    margin: 0;
  }

  .m-lg-1 {
    margin: 0.5rem;
  }

  .m-lg-2 {
    margin: 0.75rem;
  }

  .m-lg-3 {
    margin: 1rem;
  }

  .m-lg-4 {
    margin: 2rem;
  }

  .m-lg-5 {
    margin: 3rem;
  }

  .m-lg-6 {
    margin: 4rem;
  }

  .m-lg-7 {
    margin: 5rem;
  }

  .m-lg-auto {
    margin: auto;
  }

  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-lg-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .mx-lg-2 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }

  .mx-lg-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .mx-lg-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .mx-lg-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .mx-lg-6 {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .mx-lg-7 {
    margin-right: 5rem;
    margin-left: 5rem;
  }

  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-lg-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-lg-2 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .my-lg-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-lg-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .my-lg-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-lg-6 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .my-lg-7 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-lg-0 {
    margin-top: 0;
  }

  .mt-lg-1 {
    margin-top: 0.5rem;
  }

  .mt-lg-2 {
    margin-top: 0.75rem;
  }

  .mt-lg-3 {
    margin-top: 1rem;
  }

  .mt-lg-4, .cta .btn, .cta .btn-play {
    margin-top: 2rem;
  }

  .mt-lg-5 {
    margin-top: 3rem;
  }

  .mt-lg-6 {
    margin-top: 4rem;
  }

  .mt-lg-7 {
    margin-top: 5rem;
  }

  .mt-lg-auto {
    margin-top: auto;
  }

  .me-lg-0 {
    margin-right: 0;
  }

  .me-lg-1 {
    margin-right: 0.5rem;
  }

  .me-lg-2 {
    margin-right: 0.75rem;
  }

  .me-lg-3 {
    margin-right: 1rem;
  }

  .me-lg-4 {
    margin-right: 2rem;
  }

  .me-lg-5 {
    margin-right: 3rem;
  }

  .me-lg-6 {
    margin-right: 4rem;
  }

  .me-lg-7 {
    margin-right: 5rem;
  }

  .me-lg-auto {
    margin-right: auto;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }

  .mb-lg-1 {
    margin-bottom: 0.5rem;
  }

  .mb-lg-2 {
    margin-bottom: 0.75rem;
  }

  .mb-lg-3 {
    margin-bottom: 1rem;
  }

  .mb-lg-4 {
    margin-bottom: 2rem;
  }

  .mb-lg-5, .section-details {
    margin-bottom: 3rem;
  }

  .mb-lg-6, .section-intro {
    margin-bottom: 4rem;
  }

  .mb-lg-7 {
    margin-bottom: 5rem;
  }

  .mb-lg-auto {
    margin-bottom: auto;
  }

  .ms-lg-0 {
    margin-left: 0;
  }

  .ms-lg-1 {
    margin-left: 0.5rem;
  }

  .ms-lg-2 {
    margin-left: 0.75rem;
  }

  .ms-lg-3 {
    margin-left: 1rem;
  }

  .ms-lg-4 {
    margin-left: 2rem;
  }

  .ms-lg-5 {
    margin-left: 3rem;
  }

  .ms-lg-6 {
    margin-left: 4rem;
  }

  .ms-lg-7 {
    margin-left: 5rem;
  }

  .ms-lg-auto {
    margin-left: auto;
  }

  .p-lg-0 {
    padding: 0;
  }

  .p-lg-1 {
    padding: 0.5rem;
  }

  .p-lg-2 {
    padding: 0.75rem;
  }

  .p-lg-3 {
    padding: 1rem;
  }

  .p-lg-4 {
    padding: 2rem;
  }

  .p-lg-5 {
    padding: 3rem;
  }

  .p-lg-6 {
    padding: 4rem;
  }

  .p-lg-7 {
    padding: 5rem;
  }

  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-lg-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .px-lg-2 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .px-lg-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .px-lg-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .px-lg-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .px-lg-6 {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .px-lg-7 {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-lg-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-lg-2, .nav-list .nav-link, .nav-list .navbar-nav .dropdown-toggle, .navbar-nav .nav-list .dropdown-toggle {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .py-lg-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-lg-4, .navbar-nav {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .py-lg-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .py-lg-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .py-lg-7 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .pt-lg-0 {
    padding-top: 0;
  }

  .pt-lg-1 {
    padding-top: 0.5rem;
  }

  .pt-lg-2 {
    padding-top: 0.75rem;
  }

  .pt-lg-3 {
    padding-top: 1rem;
  }

  .pt-lg-4 {
    padding-top: 2rem;
  }

  .pt-lg-5 {
    padding-top: 3rem;
  }

  .pt-lg-6, .section, .hero-text, .bg-main {
    padding-top: 4rem;
  }

  .pt-lg-7, .section.bg-*, .bg-*.bg-main {
    padding-top: 5rem;
  }

  .pe-lg-0 {
    padding-right: 0;
  }

  .pe-lg-1 {
    padding-right: 0.5rem;
  }

  .pe-lg-2 {
    padding-right: 0.75rem;
  }

  .pe-lg-3 {
    padding-right: 1rem;
  }

  .pe-lg-4 {
    padding-right: 2rem;
  }

  .pe-lg-5 {
    padding-right: 3rem;
  }

  .pe-lg-6 {
    padding-right: 4rem;
  }

  .pe-lg-7 {
    padding-right: 5rem;
  }

  .pb-lg-0 {
    padding-bottom: 0;
  }

  .pb-lg-1 {
    padding-bottom: 0.5rem;
  }

  .pb-lg-2, .hero-text .overtitle {
    padding-bottom: 0.75rem;
  }

  .pb-lg-3 {
    padding-bottom: 1rem;
  }

  .pb-lg-4, .section, .bg-main {
    padding-bottom: 2rem;
  }

  .pb-lg-5, .section-title, .cta {
    padding-bottom: 3rem;
  }

  .pb-lg-6 {
    padding-bottom: 4rem;
  }

  .pb-lg-7 {
    padding-bottom: 5rem;
  }

  .ps-lg-0 {
    padding-left: 0;
  }

  .ps-lg-1 {
    padding-left: 0.5rem;
  }

  .ps-lg-2 {
    padding-left: 0.75rem;
  }

  .ps-lg-3 {
    padding-left: 1rem;
  }

  .ps-lg-4 {
    padding-left: 2rem;
  }

  .ps-lg-5 {
    padding-left: 3rem;
  }

  .ps-lg-6 {
    padding-left: 4rem;
  }

  .ps-lg-7 {
    padding-left: 5rem;
  }

  .text-lg-start {
    text-align: left;
  }

  .text-lg-end {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }

  .float-xl-end {
    float: right;
  }

  .float-xl-none {
    float: none;
  }

  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-grid {
    display: grid;
  }

  .d-xl-table {
    display: table;
  }

  .d-xl-table-row {
    display: table-row;
  }

  .d-xl-table-cell {
    display: table-cell;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-inline-flex {
    display: inline-flex;
  }

  .d-xl-none {
    display: none;
  }

  .flex-xl-fill {
    flex: 1 1 auto;
  }

  .flex-xl-row {
    flex-direction: row;
  }

  .flex-xl-column {
    flex-direction: column;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xl-grow-0 {
    flex-grow: 0;
  }

  .flex-xl-grow-1 {
    flex-grow: 1;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }

  .flex-xl-wrap {
    flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .gap-xl-0 {
    gap: 0;
  }

  .gap-xl-1 {
    gap: 0.5rem;
  }

  .gap-xl-2 {
    gap: 0.75rem;
  }

  .gap-xl-3 {
    gap: 1rem;
  }

  .gap-xl-4 {
    gap: 2rem;
  }

  .gap-xl-5 {
    gap: 3rem;
  }

  .gap-xl-6 {
    gap: 4rem;
  }

  .gap-xl-7 {
    gap: 5rem;
  }

  .justify-content-xl-start {
    justify-content: flex-start;
  }

  .justify-content-xl-end {
    justify-content: flex-end;
  }

  .justify-content-xl-center {
    justify-content: center;
  }

  .justify-content-xl-between {
    justify-content: space-between;
  }

  .justify-content-xl-around {
    justify-content: space-around;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }

  .align-items-xl-start {
    align-items: flex-start;
  }

  .align-items-xl-end {
    align-items: flex-end;
  }

  .align-items-xl-center {
    align-items: center;
  }

  .align-items-xl-baseline {
    align-items: baseline;
  }

  .align-items-xl-stretch {
    align-items: stretch;
  }

  .align-content-xl-start {
    align-content: flex-start;
  }

  .align-content-xl-end {
    align-content: flex-end;
  }

  .align-content-xl-center {
    align-content: center;
  }

  .align-content-xl-between {
    align-content: space-between;
  }

  .align-content-xl-around {
    align-content: space-around;
  }

  .align-content-xl-stretch {
    align-content: stretch;
  }

  .align-self-xl-auto {
    align-self: auto;
  }

  .align-self-xl-start {
    align-self: flex-start;
  }

  .align-self-xl-end {
    align-self: flex-end;
  }

  .align-self-xl-center {
    align-self: center;
  }

  .align-self-xl-baseline {
    align-self: baseline;
  }

  .align-self-xl-stretch {
    align-self: stretch;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-last {
    order: 6;
  }

  .m-xl-0 {
    margin: 0;
  }

  .m-xl-1 {
    margin: 0.5rem;
  }

  .m-xl-2 {
    margin: 0.75rem;
  }

  .m-xl-3 {
    margin: 1rem;
  }

  .m-xl-4 {
    margin: 2rem;
  }

  .m-xl-5 {
    margin: 3rem;
  }

  .m-xl-6 {
    margin: 4rem;
  }

  .m-xl-7 {
    margin: 5rem;
  }

  .m-xl-auto {
    margin: auto;
  }

  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-xl-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .mx-xl-2 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }

  .mx-xl-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .mx-xl-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .mx-xl-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .mx-xl-6 {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .mx-xl-7 {
    margin-right: 5rem;
    margin-left: 5rem;
  }

  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xl-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-xl-2 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .my-xl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-xl-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .my-xl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-xl-6 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .my-xl-7 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xl-0 {
    margin-top: 0;
  }

  .mt-xl-1 {
    margin-top: 0.5rem;
  }

  .mt-xl-2 {
    margin-top: 0.75rem;
  }

  .mt-xl-3 {
    margin-top: 1rem;
  }

  .mt-xl-4 {
    margin-top: 2rem;
  }

  .mt-xl-5 {
    margin-top: 3rem;
  }

  .mt-xl-6 {
    margin-top: 4rem;
  }

  .mt-xl-7 {
    margin-top: 5rem;
  }

  .mt-xl-auto {
    margin-top: auto;
  }

  .me-xl-0 {
    margin-right: 0;
  }

  .me-xl-1 {
    margin-right: 0.5rem;
  }

  .me-xl-2 {
    margin-right: 0.75rem;
  }

  .me-xl-3 {
    margin-right: 1rem;
  }

  .me-xl-4 {
    margin-right: 2rem;
  }

  .me-xl-5 {
    margin-right: 3rem;
  }

  .me-xl-6 {
    margin-right: 4rem;
  }

  .me-xl-7 {
    margin-right: 5rem;
  }

  .me-xl-auto {
    margin-right: auto;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .mb-xl-1 {
    margin-bottom: 0.5rem;
  }

  .mb-xl-2 {
    margin-bottom: 0.75rem;
  }

  .mb-xl-3 {
    margin-bottom: 1rem;
  }

  .mb-xl-4 {
    margin-bottom: 2rem;
  }

  .mb-xl-5 {
    margin-bottom: 3rem;
  }

  .mb-xl-6 {
    margin-bottom: 4rem;
  }

  .mb-xl-7 {
    margin-bottom: 5rem;
  }

  .mb-xl-auto {
    margin-bottom: auto;
  }

  .ms-xl-0 {
    margin-left: 0;
  }

  .ms-xl-1 {
    margin-left: 0.5rem;
  }

  .ms-xl-2 {
    margin-left: 0.75rem;
  }

  .ms-xl-3 {
    margin-left: 1rem;
  }

  .ms-xl-4 {
    margin-left: 2rem;
  }

  .ms-xl-5 {
    margin-left: 3rem;
  }

  .ms-xl-6 {
    margin-left: 4rem;
  }

  .ms-xl-7 {
    margin-left: 5rem;
  }

  .ms-xl-auto {
    margin-left: auto;
  }

  .p-xl-0 {
    padding: 0;
  }

  .p-xl-1 {
    padding: 0.5rem;
  }

  .p-xl-2 {
    padding: 0.75rem;
  }

  .p-xl-3 {
    padding: 1rem;
  }

  .p-xl-4 {
    padding: 2rem;
  }

  .p-xl-5 {
    padding: 3rem;
  }

  .p-xl-6 {
    padding: 4rem;
  }

  .p-xl-7 {
    padding: 5rem;
  }

  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-xl-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .px-xl-2 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .px-xl-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .px-xl-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .px-xl-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .px-xl-6 {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .px-xl-7 {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xl-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-xl-2 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .py-xl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-xl-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .py-xl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .py-xl-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .py-xl-7 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .pt-xl-0 {
    padding-top: 0;
  }

  .pt-xl-1 {
    padding-top: 0.5rem;
  }

  .pt-xl-2 {
    padding-top: 0.75rem;
  }

  .pt-xl-3 {
    padding-top: 1rem;
  }

  .pt-xl-4 {
    padding-top: 2rem;
  }

  .pt-xl-5 {
    padding-top: 3rem;
  }

  .pt-xl-6 {
    padding-top: 4rem;
  }

  .pt-xl-7 {
    padding-top: 5rem;
  }

  .pe-xl-0 {
    padding-right: 0;
  }

  .pe-xl-1 {
    padding-right: 0.5rem;
  }

  .pe-xl-2 {
    padding-right: 0.75rem;
  }

  .pe-xl-3 {
    padding-right: 1rem;
  }

  .pe-xl-4 {
    padding-right: 2rem;
  }

  .pe-xl-5 {
    padding-right: 3rem;
  }

  .pe-xl-6 {
    padding-right: 4rem;
  }

  .pe-xl-7 {
    padding-right: 5rem;
  }

  .pb-xl-0 {
    padding-bottom: 0;
  }

  .pb-xl-1 {
    padding-bottom: 0.5rem;
  }

  .pb-xl-2 {
    padding-bottom: 0.75rem;
  }

  .pb-xl-3 {
    padding-bottom: 1rem;
  }

  .pb-xl-4 {
    padding-bottom: 2rem;
  }

  .pb-xl-5 {
    padding-bottom: 3rem;
  }

  .pb-xl-6 {
    padding-bottom: 4rem;
  }

  .pb-xl-7 {
    padding-bottom: 5rem;
  }

  .ps-xl-0 {
    padding-left: 0;
  }

  .ps-xl-1 {
    padding-left: 0.5rem;
  }

  .ps-xl-2 {
    padding-left: 0.75rem;
  }

  .ps-xl-3 {
    padding-left: 1rem;
  }

  .ps-xl-4 {
    padding-left: 2rem;
  }

  .ps-xl-5 {
    padding-left: 3rem;
  }

  .ps-xl-6 {
    padding-left: 4rem;
  }

  .ps-xl-7 {
    padding-left: 5rem;
  }

  .text-xl-start {
    text-align: left;
  }

  .text-xl-end {
    text-align: right;
  }

  .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left;
  }

  .float-xxl-end {
    float: right;
  }

  .float-xxl-none {
    float: none;
  }

  .d-xxl-inline {
    display: inline;
  }

  .d-xxl-inline-block {
    display: inline-block;
  }

  .d-xxl-block {
    display: block;
  }

  .d-xxl-grid {
    display: grid;
  }

  .d-xxl-table {
    display: table;
  }

  .d-xxl-table-row {
    display: table-row;
  }

  .d-xxl-table-cell {
    display: table-cell;
  }

  .d-xxl-flex {
    display: flex;
  }

  .d-xxl-inline-flex {
    display: inline-flex;
  }

  .d-xxl-none {
    display: none;
  }

  .flex-xxl-fill {
    flex: 1 1 auto;
  }

  .flex-xxl-row {
    flex-direction: row;
  }

  .flex-xxl-column {
    flex-direction: column;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .gap-xxl-0 {
    gap: 0;
  }

  .gap-xxl-1 {
    gap: 0.5rem;
  }

  .gap-xxl-2 {
    gap: 0.75rem;
  }

  .gap-xxl-3 {
    gap: 1rem;
  }

  .gap-xxl-4 {
    gap: 2rem;
  }

  .gap-xxl-5 {
    gap: 3rem;
  }

  .gap-xxl-6 {
    gap: 4rem;
  }

  .gap-xxl-7 {
    gap: 5rem;
  }

  .justify-content-xxl-start {
    justify-content: flex-start;
  }

  .justify-content-xxl-end {
    justify-content: flex-end;
  }

  .justify-content-xxl-center {
    justify-content: center;
  }

  .justify-content-xxl-between {
    justify-content: space-between;
  }

  .justify-content-xxl-around {
    justify-content: space-around;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }

  .align-items-xxl-start {
    align-items: flex-start;
  }

  .align-items-xxl-end {
    align-items: flex-end;
  }

  .align-items-xxl-center {
    align-items: center;
  }

  .align-items-xxl-baseline {
    align-items: baseline;
  }

  .align-items-xxl-stretch {
    align-items: stretch;
  }

  .align-content-xxl-start {
    align-content: flex-start;
  }

  .align-content-xxl-end {
    align-content: flex-end;
  }

  .align-content-xxl-center {
    align-content: center;
  }

  .align-content-xxl-between {
    align-content: space-between;
  }

  .align-content-xxl-around {
    align-content: space-around;
  }

  .align-content-xxl-stretch {
    align-content: stretch;
  }

  .align-self-xxl-auto {
    align-self: auto;
  }

  .align-self-xxl-start {
    align-self: flex-start;
  }

  .align-self-xxl-end {
    align-self: flex-end;
  }

  .align-self-xxl-center {
    align-self: center;
  }

  .align-self-xxl-baseline {
    align-self: baseline;
  }

  .align-self-xxl-stretch {
    align-self: stretch;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-last {
    order: 6;
  }

  .m-xxl-0 {
    margin: 0;
  }

  .m-xxl-1 {
    margin: 0.5rem;
  }

  .m-xxl-2 {
    margin: 0.75rem;
  }

  .m-xxl-3 {
    margin: 1rem;
  }

  .m-xxl-4 {
    margin: 2rem;
  }

  .m-xxl-5 {
    margin: 3rem;
  }

  .m-xxl-6 {
    margin: 4rem;
  }

  .m-xxl-7 {
    margin: 5rem;
  }

  .m-xxl-auto {
    margin: auto;
  }

  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-xxl-1 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .mx-xxl-2 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }

  .mx-xxl-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .mx-xxl-4 {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .mx-xxl-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .mx-xxl-6 {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .mx-xxl-7 {
    margin-right: 5rem;
    margin-left: 5rem;
  }

  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xxl-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-xxl-2 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .my-xxl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-xxl-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .my-xxl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-xxl-6 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .my-xxl-7 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xxl-0 {
    margin-top: 0;
  }

  .mt-xxl-1 {
    margin-top: 0.5rem;
  }

  .mt-xxl-2 {
    margin-top: 0.75rem;
  }

  .mt-xxl-3 {
    margin-top: 1rem;
  }

  .mt-xxl-4 {
    margin-top: 2rem;
  }

  .mt-xxl-5 {
    margin-top: 3rem;
  }

  .mt-xxl-6 {
    margin-top: 4rem;
  }

  .mt-xxl-7 {
    margin-top: 5rem;
  }

  .mt-xxl-auto {
    margin-top: auto;
  }

  .me-xxl-0 {
    margin-right: 0;
  }

  .me-xxl-1 {
    margin-right: 0.5rem;
  }

  .me-xxl-2 {
    margin-right: 0.75rem;
  }

  .me-xxl-3 {
    margin-right: 1rem;
  }

  .me-xxl-4 {
    margin-right: 2rem;
  }

  .me-xxl-5 {
    margin-right: 3rem;
  }

  .me-xxl-6 {
    margin-right: 4rem;
  }

  .me-xxl-7 {
    margin-right: 5rem;
  }

  .me-xxl-auto {
    margin-right: auto;
  }

  .mb-xxl-0 {
    margin-bottom: 0;
  }

  .mb-xxl-1 {
    margin-bottom: 0.5rem;
  }

  .mb-xxl-2 {
    margin-bottom: 0.75rem;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem;
  }

  .mb-xxl-4 {
    margin-bottom: 2rem;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem;
  }

  .mb-xxl-6 {
    margin-bottom: 4rem;
  }

  .mb-xxl-7 {
    margin-bottom: 5rem;
  }

  .mb-xxl-auto {
    margin-bottom: auto;
  }

  .ms-xxl-0 {
    margin-left: 0;
  }

  .ms-xxl-1 {
    margin-left: 0.5rem;
  }

  .ms-xxl-2 {
    margin-left: 0.75rem;
  }

  .ms-xxl-3 {
    margin-left: 1rem;
  }

  .ms-xxl-4 {
    margin-left: 2rem;
  }

  .ms-xxl-5 {
    margin-left: 3rem;
  }

  .ms-xxl-6 {
    margin-left: 4rem;
  }

  .ms-xxl-7 {
    margin-left: 5rem;
  }

  .ms-xxl-auto {
    margin-left: auto;
  }

  .p-xxl-0 {
    padding: 0;
  }

  .p-xxl-1 {
    padding: 0.5rem;
  }

  .p-xxl-2 {
    padding: 0.75rem;
  }

  .p-xxl-3 {
    padding: 1rem;
  }

  .p-xxl-4 {
    padding: 2rem;
  }

  .p-xxl-5 {
    padding: 3rem;
  }

  .p-xxl-6 {
    padding: 4rem;
  }

  .p-xxl-7 {
    padding: 5rem;
  }

  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-xxl-1 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .px-xxl-2 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .px-xxl-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .px-xxl-4 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .px-xxl-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .px-xxl-6 {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .px-xxl-7 {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xxl-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-xxl-2 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .py-xxl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-xxl-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .py-xxl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .py-xxl-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .py-xxl-7 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .pt-xxl-0 {
    padding-top: 0;
  }

  .pt-xxl-1 {
    padding-top: 0.5rem;
  }

  .pt-xxl-2 {
    padding-top: 0.75rem;
  }

  .pt-xxl-3 {
    padding-top: 1rem;
  }

  .pt-xxl-4 {
    padding-top: 2rem;
  }

  .pt-xxl-5 {
    padding-top: 3rem;
  }

  .pt-xxl-6 {
    padding-top: 4rem;
  }

  .pt-xxl-7 {
    padding-top: 5rem;
  }

  .pe-xxl-0 {
    padding-right: 0;
  }

  .pe-xxl-1 {
    padding-right: 0.5rem;
  }

  .pe-xxl-2 {
    padding-right: 0.75rem;
  }

  .pe-xxl-3 {
    padding-right: 1rem;
  }

  .pe-xxl-4 {
    padding-right: 2rem;
  }

  .pe-xxl-5 {
    padding-right: 3rem;
  }

  .pe-xxl-6 {
    padding-right: 4rem;
  }

  .pe-xxl-7 {
    padding-right: 5rem;
  }

  .pb-xxl-0 {
    padding-bottom: 0;
  }

  .pb-xxl-1 {
    padding-bottom: 0.5rem;
  }

  .pb-xxl-2 {
    padding-bottom: 0.75rem;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem;
  }

  .pb-xxl-4 {
    padding-bottom: 2rem;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem;
  }

  .pb-xxl-6 {
    padding-bottom: 4rem;
  }

  .pb-xxl-7 {
    padding-bottom: 5rem;
  }

  .ps-xxl-0 {
    padding-left: 0;
  }

  .ps-xxl-1 {
    padding-left: 0.5rem;
  }

  .ps-xxl-2 {
    padding-left: 0.75rem;
  }

  .ps-xxl-3 {
    padding-left: 1rem;
  }

  .ps-xxl-4 {
    padding-left: 2rem;
  }

  .ps-xxl-5 {
    padding-left: 3rem;
  }

  .ps-xxl-6 {
    padding-left: 4rem;
  }

  .ps-xxl-7 {
    padding-left: 5rem;
  }

  .text-xxl-start {
    text-align: left;
  }

  .text-xxl-end {
    text-align: right;
  }

  .text-xxl-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3rem;
  }

  .fs-2 {
    font-size: 2.25rem;
  }

  .fs-3, .hero-text .overtitle, .hero-text .subtitle {
    font-size: 1.75rem;
  }

  .fs-4 {
    font-size: 1.35rem;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }

  .d-print-inline-block {
    display: inline-block;
  }

  .d-print-block {
    display: block;
  }

  .d-print-grid {
    display: grid;
  }

  .d-print-table {
    display: table;
  }

  .d-print-table-row {
    display: table-row;
  }

  .d-print-table-cell {
    display: table-cell;
  }

  .d-print-flex {
    display: flex;
  }

  .d-print-inline-flex {
    display: inline-flex;
  }

  .d-print-none {
    display: none;
  }
}

/*# sourceMappingURL=bootstrap.css.map */